import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { loggedInUserState } from '@app/domain/app';
import {
  PRIVACY_POLICY_LINK,
  TERMS_CUSTOMER_LINK,
} from '@app/static/constants';

export function TopNavMenu() {
  // ログインユーザー情報
  const [loggedInUser] = useRecoilState(loggedInUserState);
  // メニューの開閉フラグ
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  /**
   * メニューを開く
   * @returns void
   */
  const handleOpenMenu = () => {
    setIsOpenMenu(true);
  };
  /**
   * メニューを閉じる
   * @returns void
   */
  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  // メニュー項目
  const menuItems = [
    // ログインしている場合はマイアカウント、ログインしていない場合は会員登録を表示
    loggedInUser?.id
      ? { isExternalLink: false, label: 'マイアカウント', path: '/account' }
      : { isExternalLink: false, label: '会員登録', path: '/register' },
    {
      // NOTE: 遷移先のHomeCarousel.tsxが強制的なルーティングをするためlocation.stateで制御している。
      isExternalLink: false,
      label: '住宅展示場を探す',
      path: '/home?category=exhibition',
      state: { searchType: 'exhibition' },
    },
    {
      isExternalLink: false,
      label: '分譲を探す',
      path: '/home?category=property',
      state: { searchType: 'property' },
    },
    {
      isExternalLink: true,
      label: 'Terms & Conditions',
      path: TERMS_CUSTOMER_LINK,
    },
    {
      isExternalLink: true,
      label: 'Privacy Policy',
      path: PRIVACY_POLICY_LINK,
    },
  ];

  // ユーザー名
  const UserDisplayName = () =>
    loggedInUser?.displayName ? (
      <Box display="flex" alignItems="center" gap={2}>
        <AccountCircleIcon
          fontSize="large"
          sx={{ color: 'text.gray', fontSize: '48px' }}
        />
        <Typography color="text.gray" fontSize={16}>
          {loggedInUser.displayName}
        </Typography>
      </Box>
    ) : null;

  /// メニューアイテム
  const MenuItems = () => (
    <>
      {menuItems.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          target={item.isExternalLink ? '_blank' : '_self'}
          rel={item.isExternalLink ? 'noopener noreferrer' : undefined}
          style={{ color: 'inherit', textDecoration: 'none' }}
          onClick={handleCloseMenu}
          state={item.state}
        >
          <Typography color="text.primary" fontSize={16}>
            {item.label}
          </Typography>
        </Link>
      ))}{' '}
    </>
  );

  // メニュー
  const Menu = () => (
    <Box
      position="absolute"
      top={0}
      right={0}
      width="100%"
      height="100dvh"
      zIndex={4}
      padding={3}
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{
        backgroundColor: 'white',
      }}
    >
      <UserDisplayName />
      <MenuItems />
    </Box>
  );

  // 開くボタン
  const OpenMenuButton = () => (
    <IconButton
      onClick={handleOpenMenu}
      sx={{
        color: 'neutral.blueLight',
      }}
    >
      <MenuIcon />
    </IconButton>
  );

  // 閉じるボタン（メニュー本体を
  const CloseMenuButton = () => (
    <>
      <IconButton
        onClick={handleCloseMenu}
        sx={{ color: 'neutral.blueLight', zIndex: 5 }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  // メニュー開く/閉じるボタンとメニュー本体
  const renderTopNavMenu = isOpenMenu ? (
    <>
      <CloseMenuButton />
      <Menu />
    </>
  ) : (
    <OpenMenuButton />
  );

  return renderTopNavMenu;
}
