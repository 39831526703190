import { Stack, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getProfileUser } from '@app/adapter/auth-service';
import { userAuthInfoSelector } from '@app/domain/app';
import { theme } from '@app/theme';
import { GenderValue, IUserProfile, ProfessionValue } from '@app/types/user';

export function ProfileInfo(): ReactElement {
  const userAuthInfo = useRecoilValue(userAuthInfoSelector);
  const [profileUser, setProfileUser] = useState<IUserProfile>();
  useEffect(() => {
    void (async () => {
      if (userAuthInfo && userAuthInfo.accessToken) {
        const responseProfile = await getProfileUser(userAuthInfo.accessToken);
        setProfileUser(responseProfile);
      }
    })();
  }, [userAuthInfo]);
  // const navigateToEditProfile = () => {
  //   navigate(TAB_NAME.PROFILE_EDIT);
  // };

  return (
    <>
      <Stack justifyContent="space-between" direction="row" mb={4}>
        <Typography variant="h3" fontWeight="bold">
          個人情報
        </Typography>
        {/* <Button
          onClick={navigateToEditProfile}
          sx={{
            alignItems: 'center',
            border: 1,
            borderColor: theme.palette.grey[400],
            borderRadius: 1,
            color: '#333',
            display: 'inline-flex',
            fontSize: '14px',
            fontWeight: 'bold',
            px: 4,
          }}
        >
          編集する
        </Button> */}
      </Stack>
      <Stack
        spacing={3}
        sx={{
          border: 1,
          borderColor: theme.palette.grey[400],
          borderRadius: 1,
          mb: 12,
          p: 3,
        }}
      >
        <Stack>
          <Typography variant="body3" mb={1}>
            姓名
          </Typography>
          <Typography variant="body2">
            {profileUser?.user.displayName}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body3" mb={1}>
            姓名（かな）
          </Typography>
          <Typography variant="body2">{profileUser?.user.nameRuby}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body3" mb={1}>
            性別
          </Typography>
          <Typography variant="body2">
            {profileUser?.gender.code
              ? GenderValue[profileUser?.gender.code]
              : '-'}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body3" mb={1}>
            生年月日
          </Typography>
          <Typography variant="body2">
            {profileUser?.birthday.iso8601}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body3" mb={1}>
            住所
          </Typography>
          <Typography variant="body2">
            <Typography>{profileUser?.profile.postCode}</Typography>
            <Typography>{profileUser?.profile.streetAddress}</Typography>
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body3" mb={1}>
            電話番号
          </Typography>
          <Typography variant="body2">
            {profileUser?.profile.phoneNumber}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body3" mb={1}>
            職業
          </Typography>
          <Typography variant="body2">
            {profileUser?.profile.professionCode
              ? ProfessionValue[profileUser?.profile.professionCode]
              : '-'}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body3" mb={1}>
            勤務先
          </Typography>
          <Typography variant="body2">
            {profileUser?.profile.workplace}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body3" mb={1}>
            世帯年収
          </Typography>
          <Typography variant="body2">
            {(profileUser?.profile.householdIncome || 0) / 10000}万円
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
