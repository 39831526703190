import React from 'react';

export const convertNewLineToBr = (
  str: string | undefined,
  separator: string | RegExp = /\r\n|\r|\n/g
) => {
  if (!str) {
    return '';
  }
  return str.split(separator).map((s, i) => [s, <br key={i} />]);
};
