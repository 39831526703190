import { Box, Container, Divider, Grid } from '@mui/material';
import { ReactElement, useRef } from 'react';

import { CompanyInfoSp } from './CompanyInfoSp';

import { CompanyInfo } from '@app/components/Company/CompanyInfo';
import { InquiryCards } from '@app/components/Company/Inquiry/InquiryCards';
import { OrganizationDetail } from '@app/components/Company/OrganizationDetail';
import { OrganizationStrengths } from '@app/components/Company/OrganizationStrengths';
import { ProductCardList } from '@app/components/Company/ProductCardList';
import { Loading } from '@app/components/Shared/Loading';
import { useCompanyExhibitionContext } from '@app/hooks/companyExhibitionContext';
import { useDeviceType } from '@app/hooks/useBrowserHooks';

export function OrganizationInfo(): ReactElement {
  const {
    exhibitionProducts,
    organization,
    prefectures,
    propertyProducts,
    exhibitionPrefecture,
    propertyPrefecture,
    setExhibitionPrefecture,
    productNextLink,
    setPropertyPrefecture,
    handleLoadMoreExhibitionProducts,
    handleLoadMorePropertyProducts,
    isLoadingMoreExhibitionProducts,
    isLoadingMorePropertyProducts,
    isFetchingExhibitionProducts,
    isFetchingPropertyProducts,
  } = useCompanyExhibitionContext();
  const productListRef = useRef<HTMLDivElement | null>(null);
  const handleScrollToExhibitionProductList = () => {
    if (productListRef.current) {
      productListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const { isMobile } = useDeviceType();
  return (
    <>
      {!organization ? (
        <Loading />
      ) : (
        <Box>
          <Container
            sx={{ pb: 10, pr: { md: 4 }, pt: 3 }}
            maxWidth={false}
            disableGutters
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={9} sx={{ width: '100%' }}>
                <OrganizationDetail organization={organization} />
                <Divider sx={{ my: 5 }} />
                <OrganizationStrengths
                  data={organization.customFields?.strengths || []}
                />
                {isMobile ? (
                  <CompanyInfoSp organization={organization} />
                ) : (
                  <CompanyInfo organization={organization} />
                )}
                <Grid sx={{ mb: isMobile ? 0 : 12, mt: 5 }}>
                  <Box ref={productListRef}>
                    <ProductCardList
                      isFetching={isFetchingExhibitionProducts}
                      isLoadingMore={isLoadingMoreExhibitionProducts}
                      isProperty={false}
                      showLoadMore={!!productNextLink.exhibition}
                      products={exhibitionProducts || []}
                      initialVisibleItems={6}
                      prefectures={prefectures || []}
                      selectedPrefecture={exhibitionPrefecture}
                      setPrefecture={setExhibitionPrefecture}
                      handleLoadMore={handleLoadMoreExhibitionProducts}
                    />
                  </Box>
                  <ProductCardList
                    isFetching={isFetchingPropertyProducts}
                    isLoadingMore={isLoadingMorePropertyProducts}
                    isProperty={true}
                    products={propertyProducts || []}
                    initialVisibleItems={6}
                    prefectures={prefectures || []}
                    selectedPrefecture={propertyPrefecture}
                    setPrefecture={setPropertyPrefecture}
                    handleLoadMore={handleLoadMorePropertyProducts}
                    showLoadMore={!!productNextLink.property}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  alignSelf: 'flex-start',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 7,
                  position: 'sticky',
                  top: '100px',
                }}
                xs={isMobile ? 12 : undefined}
                mx={isMobile ? 2 : undefined}
              >
                <InquiryCards
                  handleScrollToExhibitionProductList={
                    handleScrollToExhibitionProductList
                  }
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
