import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';

import { theme } from '@app/theme';
import {
  Product,
  ProductEventAction,
  ProductEventDateType,
  ProductEventName,
} from '@app/types/catalog';

interface EventInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

const TableCellWithFallback = ({
  value,
}: {
  value: string | number | undefined | null | string[];
}) => (
  <TableCell>
    {Array.isArray(value)
      ? value.join(', ')
      : value
      ? String(value)
          .split('\n')
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))
      : '-'}
  </TableCell>
);

export function EventInfo({ product }: EventInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        イベント情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>タイトル</TableCellWithStyle>
              <TableCell>
                {
                  Object.values(ProductEventName).find(
                    (name) => name.key === product.customFields.eventName
                  )?.label
                }
                {product.customFields.eventAction &&
                  `（${
                    Object.values(ProductEventAction).find(
                      (action) =>
                        action.key === product.customFields.eventAction
                    )?.label
                  }）`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>日程</TableCellWithStyle>
              <TableCell>
                <Typography>
                  {
                    Object.values(ProductEventDateType).find(
                      (type) =>
                        type.key === product.customFields.eventDateType &&
                        !['fixedDate', 'between'].includes(type.key)
                    )?.label
                  }
                  {product.customFields.eventDateType === 'fixedDate' &&
                    // NOTE: 日時指定であれば、eventDateListは必須で設定されているものとする。
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    product.customFields
                      .eventDateList!.map((date) =>
                        format(
                          parse(date, 'yyyy/MM/dd', new Date()),
                          'yyyy年MM月dd日'
                        )
                      )
                      .join('／')}
                  {product.customFields.eventDateType === 'between' &&
                    `${format(
                      parse(
                        // NOTE: 期間限定であれば、eventDateSinceは必須で設定されているものとする。
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        product.customFields.eventDateSince!,
                        'yyyy/MM/dd',
                        new Date()
                      ),
                      'yyyy年MM月dd日'
                    )}〜${format(
                      parse(
                        // NOTE: 期間限定であれば、eventDateUntilは必須で設定されているものとする。
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        product.customFields.eventDateUntil!,
                        'yyyy/MM/dd',
                        new Date()
                      ),
                      'yyyy年MM月dd日'
                    )}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>時間</TableCellWithStyle>
              <TableCell>
                <Typography>
                  {product.customFields.eventTimeSince &&
                    product.customFields.eventTimeUntil &&
                    `${product.customFields.eventTimeSince.replace(
                      ':',
                      '時'
                    )}分〜${product.customFields.eventTimeUntil.replace(
                      ':',
                      '時'
                    )}分`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>備考</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.eventContent}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
