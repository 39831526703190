import { AxiosPromise } from 'axios';

import { graphqlInstance, userInstance } from '@app/adapter/axios';
import { Pagination } from '@app/types/common';
import {
  Attachment,
  AttachmentCreation,
  AttachmentWithFile,
  LoginUser,
  Preference,
  PreferenceCreation,
  PreferenceKey,
  TypeId,
  User,
  UserEmailUpdate,
  UserUpdate,
} from '@app/types/user';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { getUploadedFileUrl } from '@app/utils/fileUpload';
import { filterSyntaxGen } from '@app/utils/network';

/**
 * Register user use user-service
 * @param email
 * @param password
 * @returns
 */
export function register(
  email: string,
  password: string,
  customFields?: {
    inviteCode?: string;
    invitedCode?: string;
  }
): AxiosPromise<{ id: string }> {
  return userInstance
    .post('/users', {
      customFields,
      email,
      password,
      typeId: TypeId.DEMAND,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Verify email via one-time token use user service
 * @param token
 * @returns
 */
export function verifyEmail(
  token: string
): AxiosPromise<{ token: string; userId: string }> {
  return userInstance
    .post(`/users/verify_email`, undefined, {
      headers: getAuthorizationHeader(token),
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 *
 * @param token
 * @returns
 */
export function getUser(token: string): Promise<LoginUser> {
  const query = `
    query {
      userQuery {
        ... on UserQuery {
          currentUser {
            user {
              displayName
              email
              id
            }
          }
        }
      }
    }
  `;
  return graphqlInstance
    .post(
      '',
      {
        query,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data.data.userQuery.currentUser.user;
    })
    .catch((error) => {
      console.error('Get user error:', error);
      throw new Error('ユーザー情報の取得に失敗しました');
    });
}

/**
 * send email to reset password
 * @param email - user email
 */
export function sendResetPasswordEmail(email: string): AxiosPromise<null> {
  return userInstance.post('/users/send_reset_password_email', { email });
}

/**
 * reset password
 * @param newPassword - new password
 * @param token - onetime token
 * @returns
 */
export function resetPassword(
  newPassword: string,
  token: string
): AxiosPromise<null> {
  return userInstance
    .post(
      `/users/reset_password`,
      { password: newPassword },
      {
        headers: getAuthorizationHeader(token),
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password from account settings
 * @param userId
 * @param newPassword
 * @param token
 * @param fingerPrint
 */
export function resetPasswordFromSetting(
  userId: string,
  token: string,
  fingerPrint: string,
  newPassword: string
): AxiosPromise<{ id: string }> {
  return userInstance
    .patch(
      `/users/${userId}`,
      { password: newPassword },
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get avatar upload url
 * @param userId
 * @param contentType
 * @param contentLength
 * @param token
 * @param fingerPrint
 */
export function getAvatarUploadUrl(
  userId: string,
  contentType: string,
  contentLength: number,
  token: string,
  fingerPrint: string
): AxiosPromise<string> {
  const params = new URLSearchParams();
  params.append('contentType', contentType);
  params.append('contentLength', contentLength.toString());

  return userInstance
    .get(`/users/${userId}/avatarUploadUrl?${params}`, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
}

/**
 * Update user avatar
 */
export function updateAvatar(
  userId: string,
  avatar: string,
  token: string,
  fingerPrint: string
): AxiosPromise<{ id: string }> {
  return userInstance
    .patch(
      `/users/${userId}`,
      {
        avatar,
      },
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Update user details
 */
export function update(
  userId: string,
  token: string,
  fingerPrint: string,
  payload: UserUpdate | UserEmailUpdate
): AxiosPromise<User> {
  return userInstance
    .patch(`/users/${userId}`, payload, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * preference
 */
export function getPreferences(
  userId: string,
  token: string,
  fingerPrint: string,
  payload: {
    key?: PreferenceKey;
    limit?: number;
  }
): AxiosPromise<Pagination<Preference[]>> {
  const filterParams = [];
  const urlParams = [['$top', String(payload?.limit ?? 20)]];

  if (payload?.key) {
    filterParams.push(`key eq '${payload.key}'`);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return userInstance.get<Pagination<Preference[]>>(
    `/users/${userId}/preferences?${new URLSearchParams(urlParams).toString()}`,
    {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    }
  );
}

export function postPreference(
  userId: string,
  token: string,
  fingerPrint: string,
  payload: PreferenceCreation
): AxiosPromise<Preference> {
  return userInstance.post(`/users/${userId}/preferences`, payload, {
    headers: {
      'x-nb-fingerprint': fingerPrint,
      ...getAuthorizationHeader(token),
    },
  });
}

export function deletePreference(
  userId: string,
  token: string,
  fingerPrint: string,
  id: string
): AxiosPromise<null> {
  return userInstance.delete(`/users/${userId}/preferences/${id}`, {
    headers: {
      'x-nb-fingerprint': fingerPrint,
      ...getAuthorizationHeader(token),
    },
  });
}

/**
 * attachment
 */
export function postAttachment(
  userId: string,
  token: string,
  fingerPrint: string,
  payload: AttachmentCreation
): AxiosPromise<{ id: string; url: string }> {
  return userInstance.post(`/users/${userId}/attachments`, payload, {
    headers: {
      'x-nb-fingerprint': fingerPrint,
      ...getAuthorizationHeader(token),
    },
  });
}

export function getAttachments(
  userId: string,
  token: string,
  fingerPrint: string,
  options?: { ids?: string[]; limit?: number }
): AxiosPromise<Pagination<Attachment[]>> {
  const filterParams = [];
  if (options?.ids?.length) {
    filterParams.push(`id in ${filterSyntaxGen(options?.ids)}`);
  }

  const urlParams = [['$top', String(options?.limit ?? 100)]];
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return userInstance
    .get(
      `/users/${userId}/attachments?${new URLSearchParams(
        urlParams
      ).toString()}`,
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteAttachment(
  userId: string,
  token: string,
  fingerPrint: string,
  id: string
): AxiosPromise<Pagination<Attachment[]>> {
  return userInstance
    .delete(`/users/${userId}/attachments/${id}`, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  userId: string,
  token: string,
  fingerPrint: string,
  file: AttachmentWithFile
): Promise<Attachment> {
  if (!file.blob) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getUploadSignedUrl(
    userId,
    token,
    fingerPrint,
    file.blob
  );
  const uploadedUrl = await getUploadedFileUrl(file.blob, signedUrl);

  const objectId = new URL(uploadedUrl).pathname
    .split('?')[0]
    .replace(/^\/[^/]+\//, '');
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  let extension = 'jpg';
  if (objectSplits && objectSplits.length > 1) {
    extension = objectSplits[objectSplits.length - 1];
  }
  const response = await postAttachment(userId, token, fingerPrint, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getUploadSignedUrl(
  userId: string,
  token: string,
  fingerPrint: string,
  blob: Blob
): Promise<string> {
  return userInstance
    .get(
      `/users/${userId}/attachment-upload-url?contentLength=${blob.size}&contentType=${blob.type}`,
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .then((response) => response.data)
    .catch((e) => {
      if ('message' in e.response.data) {
        throw new Error(e.response?.data.message);
      } else {
        throw new Error(e.message);
      }
    });
}
