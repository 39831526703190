import { Box } from '@mui/material';

interface TagProps {
  color?: string;
  text?: string;
}

export function Tag({ text, color }: TagProps) {
  return (
    <Box
      sx={{
        backgroundColor: '#3279A3',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '14px',
        px: 1,
        width: 'fit-content',
      }}
    >
      {text}
    </Box>
  );
}
