import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import { format, parse, parseISO } from 'date-fns';
import React from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';

interface PublishInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

export function PublishInfo({ product }: PublishInfoProps) {
  if (typeof product.organization === 'string') {
    return null;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        情報提供日・更新日
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>情報提供日</TableCellWithStyle>
              <TableCell>
                <Typography>
                  {format(parseISO(product.updatedAt), 'yyyy年MM月dd日')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>次回更新日</TableCellWithStyle>
              <TableCell>
                <Typography>情報提供より8日以内に更新</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>取引有効期限</TableCellWithStyle>
              <TableCell>
                <Typography>
                  {product.customFields.transactionExpirationDate
                    ? format(
                        parse(
                          product.customFields.transactionExpirationDate,
                          'yyyy/MM/dd',
                          new Date()
                        ),
                        'yyyy年MM月dd日'
                      )
                    : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
