import { Product, ProductVariant } from '@app/types/catalog';
import { Organization } from '@app/types/organization';

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED', //採用
  CANCELED: 'CANCELED', //不採用、キャンセル、辞退
  CLOSED: 'CLOSED', //評価済み
  PENDING: 'PENDING', //応募時
  PROCESSING: 'PROCESSING', //評価待ち
};

type Resource = {
  uri: string;
};
export type CartResource = Resource;
export type CheckoutResource = Resource;

export interface Order {
  canceledAt: Date | null;
  cart: CartResource | null;
  checkout: CheckoutResource | null;
  closedAt: Date | null;
  createdAt: Date;
  currency: string;
  customFields: OrderCustomFields | null;
  customer: Customer;
  id: string;
  lineItems: LineItem[];
  organization: Organization | string;
  status: string;
  subtotalPrice: number;
  totalPrice: number;
  totalTax: number;
  updatedAt: Date;
}

export const OrderTypeValues = {
  EXHIBITION_REQUEST_DOC: 'exhibitionRequestDoc', // 展示場(資料請求)
  EXHIBITION_VISITOR_RESERVE: 'exhibitionVisitorReserve', // 展示場(来場予約)
  PROPERTY_REQUEST_DOC: 'propertyRequestDoc', // 分譲(資料請求)
  PROPERTY_VISITOR_RESERVE: 'propertyVisitorReserve', // 分譲(来場予約)
} as const;
export type OrderType = (typeof OrderTypeValues)[keyof typeof OrderTypeValues];

export interface OrderCustomFields {
  addressLine4: string;
  addressLine5: string;
  customForms: OrderCustomForms[];
  desiredReservationDate?: string[];
  orderType: OrderType;
  postalCode: string;
}

export interface OrderCustomForms {
  answer: string[] | string;
  question: string;
}

export interface InquiryStepOneFormData {
  cityId: string;
  customFields: {
    addressLine4: string;
    addressLine5: string;
    exhibition: string;
    postalCode: string;
    prefecture: {
      id: string;
      name: string;
    };
  };
  customer: Customer;
  items: {
    product: {
      id: string;
      name: string;
    };
    quantity: number;
    variantId?: string;
  };
  prefectureId: string;
}

export interface InquiryStepTwoFormData {
  [key: string]: string | string[] | undefined;
  desiredReservationDate1: string;
  desiredReservationDate2: string;
  desiredReservationHour1?: string;
  desiredReservationHour2?: string;
  desiredReservationMin1?: string;
  desiredReservationMin2?: string;
}

export enum FormType {
  CHECKBOX = 'checkbox',
  PULLDOWN = 'pulldown',
  RADIO = 'radio',
  TEXT = 'text',
}

export interface InquiryStepTwoQuestion {
  formType: FormType;
  otherFlg: boolean;
  otherFlg2?: boolean;
  required: boolean;
  selections: string[];
  subText: string;
  subText2?: string;
  subUnit2?: string;
  title: string;
}

export type Customer = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  age: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  prefectureName: string;
};

export type LineItem = {
  price: {
    amount: number;
    taxable: boolean;
  };
  product: Product | string;
  productName: string;
  quantity: number;
  sku: string;
  variant: ProductVariant | string;
  variantTitle: string;
};

export interface OrderItemCreation {
  productId: string;
  quantity: number;
  variantId?: string;
}

export interface OrderCustomerCreation {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  age?: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  preferredContactMethod?: string;
  preferredTimeToContact?: string;
  user?: string;
}

export interface OrderCreation {
  customFields?: OrderCustomFields | null;
  customer: OrderCustomerCreation;
  items: OrderItemCreation[];
}

export type OrderPageState = {
  organizationId?: string;
  product: Product;
};

export interface OrderUpdate {
  canceledAt?: Date;
  customFields?: OrderCustomFields | null;
  otherReason?: string;
  status?: string;
}
