import { format, isValid, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import dateFnsJa from 'date-fns/locale/ja';

import {
  ProductRangeType,
  ProductRangeTypeKey,
  TransportMeans,
  TransportMeansKey,
} from '@app/types/catalog';

const localeJP = 'ja-JP';
const currencyJP = 'JPY';
const defaultLocale = localeJP;
const defaultCurrency = currencyJP;

export function localizedPrice(price: number) {
  return new Intl.NumberFormat(defaultLocale, {
    currency: defaultCurrency,
    style: 'currency',
  }).format(price);
}

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat('ja-JP').format(price);
};

export function restoreSanitizedString(str: string) {
  let tempStr = str.replace(/\\0/g, '\0');
  tempStr = tempStr.replace(/\\n/g, '\n');
  tempStr = tempStr.replace(/\\r/g, '\r');
  return tempStr;
}

export function formatBearerToken(token: string): string {
  return `Bearer ${token}`;
}

export function jsonParse(jsonStr: string) {
  return JSON.parse(jsonStr.replace(/\\+/g, ''));
}

export function getEllipsisStyle(lines: number) {
  return {
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: lines,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
}

export function convertToTsubo(areaInSquareMeters: number): number {
  const TSUBO_CONVERSION_RATE = 3.30579;
  const result = areaInSquareMeters / TSUBO_CONVERSION_RATE;
  return parseFloat(result.toFixed(2));
}

export function formatClosingTime(closingTime?: string): string {
  if (!closingTime) return '';

  const dateFormats = ['yyyy/MM/dd HH:mm:ss', 'yyyy/MM/dd HH:mm', 'HH:mm'];
  let parsedDate: Date | undefined = undefined;
  for (const dateFormat of dateFormats) {
    const tempDate = parse(closingTime, dateFormat, new Date());
    if (isValid(tempDate)) {
      parsedDate = tempDate;
      break;
    }
  }
  if (!parsedDate || !isValid(parsedDate)) {
    return closingTime;
  }

  return format(parsedDate, 'HH:mm');
}

export const getSeparator = (rangeType?: ProductRangeTypeKey): string => {
  return rangeType === ProductRangeType.BETWEEN
    ? '〜'
    : rangeType === ProductRangeType.SEPARATE
    ? '・'
    : ' ';
};

export const formatPriceRange = (
  minPrice?: number,
  maxPrice?: number,
  formatPrice?: (price: number) => string,
  priceRangeType?: ProductRangeTypeKey
): string => {
  const separator = getSeparator(priceRangeType);
  if (minPrice && maxPrice) {
    return `${formatPrice?.(minPrice)}万円${separator}${formatPrice?.(
      maxPrice
    )}万円`;
  } else if (minPrice && !maxPrice) {
    return `${formatPrice?.(minPrice)}万円`;
  } else if (!minPrice && maxPrice) {
    return `${formatPrice?.(maxPrice)}万円`;
  } else if (minPrice === 0) {
    return '- 万円';
  } else if (minPrice && maxPrice === 0) {
    return `${formatPrice?.(minPrice)}万円`;
  }
  return '未定';
};

export const formatAreaRange = (
  minArea?: number,
  maxArea?: number,
  rangeType?: ProductRangeTypeKey
) => {
  const formatArea = (area?: number) => {
    return typeof area === 'number'
      ? parseFloat(area.toFixed(2)).toString()
      : '0.00';
  };
  const separator = getSeparator(rangeType);

  if (minArea === 0) {
    return '- ㎡';
  }

  if (minArea && maxArea) {
    return `${formatArea(minArea)}㎡${separator}${formatArea(maxArea)}㎡`;
  } else if (minArea && !maxArea) {
    return `${formatArea(minArea)}㎡`;
  } else if (maxArea && !minArea) {
    return `${formatArea(maxArea)}㎡`;
  }
  return '- ㎡';
};

export function convertUtcToJp(date?: number | string | Date): Date {
  if (!date) return new Date();
  const d = (
    toString.call(date).slice(8, -1) === 'Date' ? date : new Date(date)
  ) as Date;
  // 日本時間 (UTC+9) に変換
  const offset = 9 * 60 * 60 * 1000; // 9時間をミリ秒に変換
  // UTC time
  const utcTime = d.getTime() + d.getTimezoneOffset() * 60 * 1000; // convert to UTC time
  // add Japan offset
  return new Date(utcTime + offset);
}

export function dateFormatJp(
  date?: string | Date,
  hideWeek = true,
  empty = ''
) {
  if (!date) return empty;
  const d = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(d.getDate())) return empty;
  return format(d, `yyyy年MM月dd日${hideWeek ? '' : '(eee)'}`, {
    locale: dateFnsJa,
  });
}

export function dateConvertToFormat(
  date?: Date | string,
  dateFormat = 'yyyy/MM/dd',
  empty = ''
) {
  if (!date) return empty;
  const d = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(d.getDate())) return empty;
  return format(d, dateFormat, { locale: ja });
}

export function timestampFormatJp(
  date?: number | string,
  hideWeek = true,
  empty = ''
): string {
  if (!date) return empty;
  return dateFormatJp(convertUtcToJp(date), hideWeek, empty);
}
export const formatFloorPlan = (
  floorPlanRooms?: string[],
  floorPlanTypeMin?: string,
  floorPlanTypeMax?: string,
  floorPlanRangeType?: ProductRangeTypeKey
): string | null => {
  if (!floorPlanRooms?.[0]) {
    return null;
  }

  const separator =
    floorPlanRangeType === ProductRangeType.BETWEEN ? '〜' : '・';

  const roomCount = floorPlanRooms.length;
  const minRoom = Number(floorPlanRooms[0]);
  const maxRoom = Math.max(...floorPlanRooms.map(Number));

  let result = `${minRoom}${floorPlanTypeMin || ''}`;

  if (roomCount > 1) {
    result += `${separator}${maxRoom}`;
  } else if (floorPlanTypeMax && floorPlanTypeMin !== floorPlanTypeMax) {
    result += `${separator}${floorPlanTypeMax}`;
  }

  if (roomCount > 1 || (minRoom && floorPlanTypeMin !== floorPlanTypeMax)) {
    result += floorPlanTypeMax || '';
  }

  return result;
};

/**
 * TransportMeans の値に対応する日本語ラベルを返す関数
 * @param mean TransportMeansKey
 * @returns 日本語ラベル
 */
export function getTransportMeanLabel(mean?: TransportMeansKey): string {
  const transportMeanLabels: Record<TransportMeansKey, string> = {
    [TransportMeans.WALK]: '徒歩',
    [TransportMeans.BUS]: '',
    [TransportMeans.CAR]: '車',
  };

  return mean ? transportMeanLabels[mean] : '';
}

export function getTransportTimeLabel(
  mean?: TransportMeansKey,
  timeMin?: number,
  timeMax?: number,
  transportNote?: string
): string {
  const unit = mean === TransportMeans.CAR ? 'km' : '分';

  if (
    (timeMin === 0 || timeMin === undefined) &&
    (timeMax === 0 || timeMax === undefined)
  ) {
    return mean === TransportMeans.BUS && transportNote ? transportNote : '';
  }
  if (timeMin === 0 || timeMin === undefined) {
    return `${timeMax}${unit}`;
  }
  if (timeMax === 0 || timeMax === undefined) {
    return `${timeMin}${unit}`;
  }

  return `${timeMin}${unit}〜${timeMax}${unit}`;
}
