import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';
import { formatClosingTime } from '@app/utils/format';

interface ExhibitionInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.greyLight,
      fontWeight: 'bold',
    }}
  >
    {children}
  </TableCell>
);

export function ExhibitionInfo({ product }: ExhibitionInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        住宅展示場情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>所在地</TableCellWithStyle>
              <TableCell>
                {product.addressLine1 + product.addressLine2}
                {product.customFields.addressLine3HiddenFlag === true &&
                  product.addressLine3}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>問い合わせ</TableCellWithStyle>
              <TableCell>
                <Typography>{product.customFields.contactAddress}</Typography>
                <Typography>
                  {product.customFields.contactPhoneNumber}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: theme.palette.grey[500] }}
                >
                  {product.customFields.contactAdditional}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>営業時間</TableCellWithStyle>
              <TableCell>
                {product.customFields?.openingTime}～
                {formatClosingTime(product.customFields?.closingTime)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>定休日</TableCellWithStyle>
              <TableCell>
                {product.customFields.regularHoliday}
                {product.customFields.regularHolidayAdditional && (
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      color: theme.palette.grey[500],
                      display: 'block',
                      mt: 1,
                    }}
                  >
                    ※{product.customFields.regularHolidayAdditional}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>アクセス</TableCellWithStyle>
              <TableCell>{product.customFields.access}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
