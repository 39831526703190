import { Box, Typography, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getOrganizationsByParentIds } from '@app/adapter/organization-service';
import { getProducts } from '@app/domain/network-actions';
import { CategoryType, Product } from '@app/types/catalog';
import { getEllipsisStyle } from '@app/utils/format';

interface ShowroomListProps {
  organizationId: string;
}

export function ShowroomList({ organizationId }: ShowroomListProps) {
  const [showrooms, setShowrooms] = useState<Product[]>([]);
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    try {
      // 企業に紐づく子Organization(窓口)を取得
      const organizationResponse = await getOrganizationsByParentIds([
        organizationId,
      ]);
      const childOrganizationIds = organizationResponse.data.value.map(
        (org: { id: string }) => org.id
      );

      if (childOrganizationIds.length === 0) {
        return;
      }

      const options = {
        category: CategoryType.EXHIBITION,
        childrenIds: childOrganizationIds,
        expand: 'organization',
        limit: 20,
      };

      const response = await getProducts(options);
      setShowrooms(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [organizationId]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleShowroomClick = (event: React.MouseEvent, showroomId: string) => {
    event.stopPropagation();
    navigate(`/exhibitions/${showroomId}`);
  };

  if (showrooms.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: '980px',
        minWidth: '680px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
        住宅展示場一覧
      </Typography>
      <Box
        sx={{ display: 'flex', overflowX: 'auto', width: '100%' }}
        onWheel={handleScroll}
      >
        {showrooms.map((showroom) => (
          <Box
            key={showroom.id}
            sx={{ cursor: 'pointer', flex: '0 0 auto', width: '200px' }}
            onClick={(event) => handleShowroomClick(event, showroom.id)}
          >
            <Paper elevation={0} sx={{ height: '100%', padding: 2 }}>
              <img
                src={showroom.images[0]?.url || ''}
                alt={showroom.name}
                style={{
                  borderRadius: '4px',
                  height: '120px',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
              <Typography sx={{ mb: 1, mt: 1, ...getEllipsisStyle(2) }}>
                {showroom.name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={getEllipsisStyle(2)}
              >
                {showroom.addressLine1}
                {showroom.addressLine2}
                {showroom.customFields.addressLine3HiddenFlag
                  ? showroom.addressLine3
                  : ''}
              </Typography>
            </Paper>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
