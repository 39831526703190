import { atom, selector } from 'recoil';

import {
  getAttributes,
  getCategoryTree,
  getLocations,
  getLocationTree,
} from '@app/adapter/catalog-service';
import {
  Attribute,
  AttributeGroupName,
  Category,
  CategoryStatus,
  Product,
  ProductLocation,
  ProductLocationType,
  SearchCondition,
  SearchResultProducts,
} from '@app/types/catalog';
import { SearchResultOrganizations } from '@app/types/organization';

export const categoriesState = atom<Category[]>({
  default: [],
  key: 'catalog/categoriesState',
});
export const categorySelector = selector<Category[]>({
  get: async ({ get }) => {
    const state = get(categoriesState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getCategoryTree({
        status: CategoryStatus.ACTIVE,
      });
      return value || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/categorySelector',
  set: ({ set }, newData) => {
    set(categoriesState, newData);
  },
});

export const locationsTreeState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsTreeState',
});
export const locationsTreeSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsTreeState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocationTree({
        type: ProductLocationType.REGION,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsTreeSelector',
  set: ({ set }, newData) => {
    set(locationsTreeState, newData);
  },
});

export const locationsByPrefectureState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsByPrefectureState',
});
export const locationsByPrefectureSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsByPrefectureState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocations({
        type: ProductLocationType.PREFECTURE,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsByPrefectureSelector',
  set: ({ set }, newData) => {
    set(locationsByPrefectureState, newData);
  },
});

export const attributesByClinicalDepartmentState = atom<Attribute[]>({
  default: [],
  key: 'catalog/attributesByClinicalDepartmentState',
});
export const attributesByClinicalDepartmentSelector = selector<Attribute[]>({
  get: async ({ get }) => {
    const state = get(attributesByClinicalDepartmentState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getAttributes({
        groupName: [AttributeGroupName.CLINICAL_DEPARTMENT],
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/attributesByClinicalDepartmentSelector',
  set: ({ set }, newData) => {
    set(attributesByClinicalDepartmentState, newData);
  },
});

export const attributesByJobTypeState = atom<Attribute[]>({
  default: [],
  key: 'catalog/attributesByJobTypeState',
});
export const attributesByJobTypeSelector = selector<Attribute[]>({
  get: async ({ get }) => {
    const state = get(attributesByJobTypeState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getAttributes({
        groupName: [AttributeGroupName.JOB_TYPE],
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/attributesByJobTypeSelector',
  set: ({ set }, newData) => {
    set(attributesByJobTypeState, newData);
  },
});

export const searchResultProductsState = atom<SearchResultProducts>({
  default: {
    data: [],
    nextLink: '',
    scrollY: 0,
    total: 0,
  },
  key: 'searchResultProductsState',
});

export const searchResultOrganizationsState = atom<SearchResultOrganizations>({
  default: {
    data: [],
    nextLink: '',
    scrollY: 0,
    total: 0,
  },
  key: 'searchResultOrganizationsState',
});

export const searchResultProductsDummy = atom<SearchResultProducts>({
  default: {
    data: [],
    nextLink: '',
    scrollY: 0,
    total: 2,
  },
  key: 'searchResultProductsDummy',
});

export const searchConditionState = atom<SearchCondition | null>({
  default: null,
  key: 'searchConditionState',
});

export const searchProductsState = atom<Product[]>({
  default: [],
  key: 'searchProductsState',
});

export const searchProductTotalState = atom<number>({
  default: 0,
  key: 'searchProductTotalState',
});
