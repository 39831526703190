import { Box, Container, Grid, Typography, Divider } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { ExhibitionInfoSp } from './ExhibitionInfoSp';

import {
  getCategories,
  getOrganizationProducts,
} from '@app/adapter/catalog-service';
import { ExhibitionFloorPlan } from '@app/components/Product/ExhibitionFloorPlan';
import { ExhibitionInfo } from '@app/components/Product/ExhibitionInfo';
import {
  InquiryCardsHorizontal,
  InquiryCardsVertical,
} from '@app/components/Product/InquiryCards';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { ProductDetails } from '@app/components/Product/ProductDetails';
import { Loading } from '@app/components/Shared/Loading';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { CategoryType, Product, ProductVariantSku } from '@app/types/catalog';
import { Organization } from '@app/types/organization';

interface ProductDetailInfoProps {
  product: Product;
}

export function ProductDetailInfo({
  product,
}: ProductDetailInfoProps): ReactElement {
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [resultProducts, setResultProducts] = useState<Product[]>([]);
  const { isDesktop, isMobile } = useDeviceType();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const floorPlanVariants = product.variants.filter(
    (variant) => variant.sku === ProductVariantSku.FLOOR_PLAN
  );

  const fetchCategoryId = useCallback(async () => {
    try {
      const response = await getCategories();
      const propertyCategory = response.data.value.find(
        (category) => category.name === CategoryType.PROPERTY
      );
      setCategoryId(propertyCategory ? propertyCategory.id : null);
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!categoryId) return;
    setIsLoading(true);
    try {
      const response = await getOrganizationProducts(
        product.organizationId,
        categoryId
      );
      setResultProducts(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    } finally {
      setIsLoading(false);
    }
  }, [product.organizationId, categoryId]);

  useEffect(() => {
    void fetchCategoryId();
  }, [fetchCategoryId]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  return (
    <>
      {!product ? (
        <Loading />
      ) : (
        <Box maxWidth="100vw">
          <Container sx={{ pb: 10, pt: 3, px: 4 }} maxWidth="xl" disableGutters>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9} sx={{ width: '100%' }}>
                <ProductDetails product={product} />
                {floorPlanVariants.length > 0 ? (
                  <ExhibitionFloorPlan variants={floorPlanVariants} />
                ) : (
                  <Divider sx={{ my: 8 }} />
                )}
                {isMobile ? (
                  <ExhibitionInfoSp product={product} />
                ) : (
                  <ExhibitionInfo product={product} />
                )}
                {isMobile ? (
                  <InquiryCardsVertical
                    organizationId={product.organizationId}
                    isExhibition={true}
                    parentOrganizationId={
                      (product.organization as Organization)?.parentId || ''
                    }
                  />
                ) : (
                  <InquiryCardsHorizontal
                    type={CategoryType.EXHIBITION}
                    organizationId={product.organizationId}
                    isExhibition={true}
                    parentOrganizationId={
                      (product.organization as Organization)?.parentId || ''
                    }
                  />
                )}

                {isLoading ? (
                  <Loading />
                ) : (
                  resultProducts.length > 0 && (
                    <>
                      <Grid sx={{ mb: isMobile ? 0 : 12, mt: 5 }}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          textAlign="left"
                          sx={{ mx: isMobile ? 0 : 3 }}
                        >
                          おすすめの分譲情報
                        </Typography>
                        <ProductCardList
                          products={resultProducts}
                          initialVisibleItems={6}
                          isProperty={true}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
              {
                // PCではサイドに問い合わせカードを表示
              }
              {isDesktop && (
                <Grid
                  item
                  md={3}
                  sx={{
                    alignSelf: 'flex-start',
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 7,
                    position: 'sticky',
                    top: '100px',
                  }}
                >
                  <InquiryCardsVertical
                    organizationId={product.organizationId}
                    isExhibition={true}
                    parentOrganizationId={
                      (product.organization as Organization)?.parentId || ''
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
