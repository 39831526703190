import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ExhibitionCard, PropertyCard } from '@app/components/Product/Card';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { locationsTreeSelector } from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import { Product, ProductLocation } from '@app/types/catalog';

interface ProductCardListProps {
  handleLoadMore: () => Promise<void>;
  initialVisibleItems?: number;
  isFetching: boolean;
  isLoadingMore: boolean;
  isProperty: boolean;
  prefectures: ProductLocation[];
  products: Product[];
  selectedPrefecture: string[];
  setPrefecture: React.Dispatch<React.SetStateAction<string[]>>;
  showLoadMore: boolean;
}

export function ProductCardList({
  products,
  prefectures,
  selectedPrefecture,
  setPrefecture,
  handleLoadMore,
  isFetching,
  isLoadingMore,
  isProperty,
  showLoadMore,
}: ProductCardListProps): ReactElement {
  const { isMobile } = useDeviceType();
  const locations = useRecoilValue(locationsTreeSelector);

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const renderSelectBoxContent = () => {
    const items: React.ReactNode[] = [
      <MenuItem key="all" value="">
        全国
      </MenuItem>,
    ];

    locations.forEach((region) => {
      items.push(
        <MenuItem
          key={region.id}
          value={region.id}
          style={{ backgroundColor: '#f9f9f9', fontWeight: 'bold' }}
        >
          {region.name}
        </MenuItem>
      );

      region.children?.forEach((prefecture) => {
        items.push(
          <MenuItem
            key={prefecture.id}
            value={prefecture.id}
            style={{ paddingLeft: '20px' }}
          >
            {prefecture.name}
          </MenuItem>
        );
      });
    });

    return items;
  };

  return (
    <Stack
      spacing={4}
      sx={{ mt: 5 }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        px={8}
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        width="100%"
        justifyContent="flex-start"
      >
        <Typography variant="h3" fontWeight="bold" py={3} mr={3}>
          {isProperty ? '分譲一覧' : '住宅展示場一覧'}
        </Typography>
        <Typography variant="body3" color="textSecondary" mr={1}>
          エリア
        </Typography>
        <Box>
          <FormControl sx={{ height: '40px', width: '200px' }}>
            <Select
              multiple
              open={isSelectOpen}
              onOpen={() => setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen(false)}
              value={selectedPrefecture}
              name="prefecture"
              onChange={(event: SelectChangeEvent<string[]>) => {
                const value = event.target.value as string[];
                const latestValue = value[value.length - 1];

                const selected = new Set<string>();

                if (latestValue === '') {
                  setPrefecture([]);
                } else {
                  const region = locations.find(
                    (region) => region.id === latestValue
                  );
                  if (region) {
                    region.children?.forEach((child) => selected.add(child.id));
                  } else {
                    selected.add(latestValue);
                  }
                  setPrefecture(Array.from(selected));
                }
                setIsSelectOpen(false);
              }}
              displayEmpty
              renderValue={() => {
                if (selectedPrefecture.length === 0) {
                  return '全国';
                }

                const selectedNames = locations.flatMap((region) => {
                  const isRegionSelected = region.children?.every((child) =>
                    selectedPrefecture.includes(child.id)
                  );
                  if (isRegionSelected) {
                    return [region.name];
                  }
                  return (
                    region.children
                      ?.filter((child) => selectedPrefecture.includes(child.id))
                      .map((child) => child.name) || []
                  );
                });

                return selectedNames.join(', ');
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {renderSelectBoxContent()}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container spacing={1} sx={{ paddingX: '48px' }}>
            {products.length ? (
              products.map((data, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={index}
                  sx={{ mb: 5 }}
                >
                  {isProperty ? (
                    <PropertyCard
                      imageSrc={data.images[0]?.url || ''}
                      title={data.name}
                      address={
                        `${data.addressLine1}${data.addressLine2}` +
                        (data.customFields.addressLine3HiddenFlag
                          ? data.addressLine3
                          : '')
                      }
                      minPrice={data.customFields?.minPrice}
                      maxPrice={data.customFields?.maxPrice}
                      productId={data.id}
                      priceRangeType={data.customFields.priceRangeType}
                    />
                  ) : (
                    <ExhibitionCard
                      imageSrc={data.images[0]?.url}
                      title={data.name}
                      address={
                        `${data.addressLine1}${data.addressLine2}` +
                        (data.customFields.addressLine3HiddenFlag
                          ? data.addressLine3
                          : '')
                      }
                      productId={data.id}
                    />
                  )}
                </Grid>
              ))
            ) : (
              <Typography
                variant="body2"
                textAlign="center"
                width="100%"
                color={theme.palette.text.secondary}
              >
                {isProperty ? '物件なし' : '展示場なし'}
              </Typography>
            )}
          </Grid>
          {showLoadMore && (
            <LoadingButton
              variant="outlined"
              onClick={handleLoadMore}
              loading={isLoadingMore}
              sx={{
                borderColor: theme.palette.grey[300],
                borderRadius: 1,
                color: 'black !important',
                fontWeight: 'normal',
                px: 10,
                py: 2,
              }}
            >
              もっと見る
            </LoadingButton>
          )}
        </>
      )}
    </Stack>
  );
}
