import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { useLocation } from '@app/hooks/useLocation';
import { ProductLocation } from '@app/types/catalog';
import { InquiryStepOneFormData } from '@app/types/order';

export interface LocationsWithPostalCodeProps {
  addressAreaDirection?: 'row' | 'column';
  size?: 'medium' | 'small';
}

export function LocationsWithPostalCode({
  addressAreaDirection = 'column',
  size = 'medium',
}: LocationsWithPostalCodeProps): ReactElement {
  const {
    cities,
    fetchLocationCities,
    isCityLoading,
    prefectures,
    setPrefectureId,
  } = useLocation();
  const [isLoading, setLoading] = useState(false);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<InquiryStepOneFormData>();

  const formWatch = useWatch({ control });

  const changePrefecture = async (
    prefecture: string
  ): Promise<ProductLocation[]> => {
    clearErrors('customer.addressLine2');
    setValue('customer.addressLine1', prefecture);
    setValue('customer.addressLine2', '');
    return await fetchLocationCities(prefecture);
  };

  const changeCity = (city: string) => {
    const cityId = cities.find((p) => p.name === city)?.id || '';
    setValue('cityId', cityId);
    if (cityId) {
      clearErrors('cityId');
    }
  };

  const handleSearchPostalCode = async () => {
    if (!canSearch) return;
    setLoading(true);
    clearErrors(['customer.addressLine1', 'customer.addressLine2']);
    try {
      const response = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${formWatch.customFields?.postalCode}`
      );
      const { address1, address2 } = response.data.results[0] || '';
      if (address1) {
        const prefectureId =
          prefectures.find((pre) => pre.name === address1)?.id || '';
        setValue('prefectureId', prefectureId);
        setValue('customer.addressLine1', address1);
        if (address1) {
          clearErrors('customer.addressLine1');
        }
        const cityData = await changePrefecture(address1);
        if (address2) {
          const city = cityData.find((p) => p.name === address2);
          setValue('customer.addressLine2', address2 || '');
          setValue('cityId', city?.id || '');
          if (city?.name) {
            clearErrors('customer.addressLine2');
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const canSearch = useMemo(() => {
    const code =
      formWatch.customFields?.postalCode?.match(/\d+/g)?.join('') || '';
    return code.length === 7;
  }, [formWatch.customFields?.postalCode]);

  useEffect(() => {
    setPrefectureId(formWatch.prefectureId);
  }, [formWatch.prefectureId, setPrefectureId]);

  return (
    <Stack spacing={1}>
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            color="textPrimary"
            variant="body2"
            fontWeight={400}
            sx={{ mb: 1, minWidth: '145px' }}
          >
            郵便番号
          </Typography>
          <Typography variant="body2">〒</Typography>
          <Controller
            name="customFields.postalCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size={size}
                error={!!error}
                placeholder="1000001"
                inputProps={{ maxLength: 7 }}
                sx={{ width: { md: 160, xs: 120 } }}
              />
            )}
          />
          <LoadingButton
            variant="outlined"
            size="small"
            loading={isLoading}
            disabled={!canSearch}
            onClick={() => handleSearchPostalCode()}
            sx={{ height: 32 }}
          >
            <Typography variant="body3" style={{ whiteSpace: 'nowrap' }}>
              住所を検索
            </Typography>
          </LoadingButton>
        </Stack>
        {addressAreaDirection === 'column' &&
          errors.customFields?.postalCode && (
            <FormHelperText error sx={{ ml: '167px' }}>
              {errors.customFields.postalCode.message as string}
            </FormHelperText>
          )}
      </Box>
      <Box>
        <Stack spacing={1} direction="row">
          <Typography
            color="textPrimary"
            variant="body2"
            fontWeight={400}
            sx={{ mb: 1, minWidth: '145px' }}
          >
            都道府県
          </Typography>
          <Controller
            name="customer.addressLine1"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl sx={{ flexGrow: { md: 0, xs: 1 } }}>
                <Select
                  {...field}
                  size={size}
                  error={!!error}
                  displayEmpty
                  renderValue={(value: string) => {
                    if (value) return value;
                    return (
                      <Typography variant="body2" color="secondary">
                        都道府県
                      </Typography>
                    );
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    void changePrefecture(e.target.value);
                  }}
                  sx={{ width: { md: 182, xs: '100%' } }}
                >
                  <MenuItem value="">{'　'}</MenuItem>
                  {prefectures.map((prefecture, index) => (
                    <MenuItem key={index} value={prefecture.name}>
                      {prefecture.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.customer?.addressLine1 && (
                  <FormHelperText error>
                    {errors.customer.addressLine1.message as string}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Stack>
      </Box>

      <Box>
        <Stack spacing={1} direction="row">
          <Typography
            color="textPrimary"
            variant="body2"
            fontWeight={400}
            sx={{ mb: 1, minWidth: '145px' }}
          >
            市区町村
          </Typography>
          <Controller
            name="customer.addressLine2"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl sx={{ flexGrow: { md: 0, xs: 1 } }}>
                <Select
                  {...field}
                  value={cities.length ? field.value : ''}
                  size={size}
                  disabled={!cities.length || isCityLoading}
                  error={!!error}
                  displayEmpty
                  renderValue={(value: string) => {
                    if (value) return value;
                    if (isCityLoading)
                      return (
                        <Box textAlign="center">
                          <CircularProgress size={16} />
                        </Box>
                      );
                    return (
                      <Typography variant="body2" color="secondary">
                        市区町村
                      </Typography>
                    );
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    changeCity(e.target.value);
                  }}
                  sx={{ width: { md: 182, xs: '100%' } }}
                >
                  <MenuItem value="">{'　'}</MenuItem>
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
                {addressAreaDirection === 'column' &&
                  errors.customer?.addressLine2 && (
                    <FormHelperText error>
                      {errors.customer.addressLine2.message as string}
                    </FormHelperText>
                  )}
              </FormControl>
            )}
          />
        </Stack>
      </Box>
    </Stack>
  );
}
