import { Grid, Typography, Box, Stack, Divider } from '@mui/material';
import React, { ReactElement } from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';
import {
  formatPrice,
  formatPriceRange,
  getTransportMeanLabel,
  getTransportTimeLabel,
} from '@app/utils/format';

interface PropertySalesInfoProps {
  product: Product;
}

export function PropertySalesInfo({
  product,
}: PropertySalesInfoProps): ReactElement {
  const { isMobile } = useDeviceType();
  return (
    <Box sx={{ width: 1 }}>
      <Divider sx={{ mt: 2 }} />
      <Grid container direction="column">
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>
          販売概要
        </Typography>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
              mt: 3,
            }}
          >
            {formatPriceRange(
              product.customFields.minPrice
                ? Number(product.customFields.minPrice)
                : undefined,
              product.customFields.maxPrice
                ? Number(product.customFields.maxPrice)
                : undefined,
              formatPrice,
              product.customFields.priceRangeType
            )}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          mt: 2,
          padding: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 12 : 6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                交通
              </Typography>
              <Stack>
                {product.customFields.transportMainLine ||
                product.customFields.transportSub1Line ||
                product.customFields.transportSub2Line ? (
                  <>
                    {product.customFields.transportMainLine && (
                      <Typography variant="body2">
                        {product.customFields.transportMainLine}「
                        {product.customFields.transportMainNearest}」まで
                        {getTransportMeanLabel(
                          product.customFields.transportMainMean
                        )}
                        {getTransportTimeLabel(
                          product.customFields.transportMainMean,
                          product.customFields.transportMainTimeMin,
                          product.customFields.transportMainTimeMax,
                          product.customFields.transportMainNote
                        )}
                      </Typography>
                    )}
                    {product.customFields.transportSub1Line && (
                      <Typography variant="body2">
                        {product.customFields.transportSub1Line}「
                        {product.customFields.transportSub1Nearest}」まで
                        {getTransportMeanLabel(
                          product.customFields.transportSub1Mean
                        )}
                        {getTransportTimeLabel(
                          product.customFields.transportSub1Mean,
                          product.customFields.transportSub1TimeMin,
                          product.customFields.transportSub1TimeMax,
                          product.customFields.transportSub1Note
                        )}
                      </Typography>
                    )}
                    {product.customFields.transportSub2Line && (
                      <Typography variant="body2">
                        {product.customFields.transportSub2Line}「
                        {product.customFields.transportSub2Nearest}」まで
                        {getTransportMeanLabel(
                          product.customFields.transportSub2Mean
                        )}
                        {getTransportTimeLabel(
                          product.customFields.transportSub2Mean,
                          product.customFields.transportSub2TimeMin,
                          product.customFields.transportSub2TimeMax,
                          product.customFields.transportSub2Note
                        )}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body2">-</Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                販売戸数
              </Typography>
              <Typography variant="body2">
                {product.customFields.salesHouseCount
                  ? `${product.customFields.salesHouseCount}戸`
                  : '未定'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                住所
              </Typography>
              <Typography variant="body2">
                {product.addressLine1 + product.addressLine2}
                {product.customFields.addressLine3HiddenFlag === true &&
                  product.addressLine3}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: 6 }} />
    </Box>
  );
}
