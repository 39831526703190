import { Divider, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ImageCarousel } from '@app/components/Company/CarouselItem';
import { FeaturesSection } from '@app/components/Company/FeaturesSection';
import { TextPretty } from '@app/components/Shared/TextPretty';
import { Organization } from '@app/types/organization';

interface OrganizationDetailProps {
  organization: Organization;
}

export function OrganizationDetail({ organization }: OrganizationDetailProps) {
  const theme = useTheme();

  const carouselImages = useMemo(() => {
    return organization?.customFields?.mainImages?.length
      ? (organization.customFields.mainImages.map((img) => img.url) as string[])
      : [];
  }, [organization?.customFields?.mainImages]);

  return (
    <Stack spacing={3} sx={{ px: { md: 8, xs: 2 }, width: '100%' }}>
      <Typography variant="h2" fontWeight="bold">
        {organization.name}
      </Typography>
      <Typography
        variant="body1"
        color={theme.palette.grey[500]}
        sx={{ mt: '5px !important' }}
      >
        {organization.customFields?.subText}
      </Typography>

      <ImageCarousel images={carouselImages} displayMode="largeImage" />

      <Divider />
      <Stack direction="row">
        <Typography mr={3}>施工エリア</Typography>
        <Link to={'#'} style={{ color: '#326EE6', textDecoration: 'none' }}>
          <TextPretty
            text={organization.customFields?.constructionArea}
            formType="lightBase"
          />
        </Link>
      </Stack>
      <Divider sx={{ marginBottom: '24px !important' }} />
      <FeaturesSection
        features={organization.customFields?.features || []}
        mainCatch={organization.customFields?.mainCatch || ''}
        subCatch={organization.customFields?.subCatch || ''}
        showReadMore={false}
      />
    </Stack>
  );
}
