import { Box, Container, Grid } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProduct } from '@app/adapter/catalog-service';
import { InquiryCardsHorizontal } from '@app/components/Product/InquiryCards';
import { OrganizationInfo } from '@app/components/Product/OrganizationInfo';
import { OrganizationInfoSp } from '@app/components/Product/OrganizationInfoSp';
import { ProductDetails } from '@app/components/Product/ProductDetails';
import { PropertyFloorPlan } from '@app/components/Product/PropertyFloorPlan';
import { PropertyInfo } from '@app/components/Product/PropertyInfo';
import { PropertyInfoSp } from '@app/components/Product/PropertyInfoSp';
import { PropertySalesInfo } from '@app/components/Product/PropertySalesInfo';
import { PropertyVariantSkuDetails } from '@app/components/Product/PropertyVariantSkuDetails';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { CategoryType, Product, ProductVariantSku } from '@app/types/catalog';
import { Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';

export function PropertyPreview(): ReactElement {
  const { productId } = useParams();
  const setSnackbar = useSetSnackbar();
  const [product, setProduct] = useState<Product | null>(null);
  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      const result = await getProduct(productId);
      setProduct(result.data);
    } catch (e) {
      if (isError(e)) {
        console.error('fetchProduct', e.message);
      }
      setSnackbar(true, '分譲地が見つかりません');
    }
  }, [productId, setSnackbar]);
  const { isMobile } = useDeviceType();

  useEffect(() => {
    void fetchProduct();
    // eslint-disable-next-line
  }, []);

  if (!product) {
    return <Loading />;
  }

  const floorPlanVariants = product.variants.filter(
    (variant) => variant.sku === ProductVariantSku.FLOOR_PLAN
  );

  const relevantVariants = product.variants.filter(
    (variant) =>
      variant.sku === ProductVariantSku.EXTERIOR ||
      variant.sku === ProductVariantSku.AROUND ||
      variant.sku === ProductVariantSku.EQUIPMENT ||
      variant.sku === ProductVariantSku.INTERIOR
  );

  return (
    <>
      <HeadBlock isNoIndex />
      <Box>
        <Container
          sx={{ mx: 'auto', pb: 10, pt: 3 }}
          maxWidth="xl"
          disableGutters
        >
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12} md={9} sx={{ width: '100%' }}>
              <ProductDetails product={product} />
              <PropertySalesInfo product={product} />
              <PropertyFloorPlan
                organizationId={product.organizationId}
                variants={floorPlanVariants}
              />
              {product.organizationId && (
                <InquiryCardsHorizontal
                  type={CategoryType.PROPERTY}
                  isExhibition={false}
                  organizationId={product.organizationId}
                  parentOrganizationId={
                    (product.organization as Organization)?.parentId || ''
                  }
                />
              )}
              {relevantVariants.length > 0 && (
                <>
                  <PropertyVariantSkuDetails variants={relevantVariants} />
                  {product.organizationId && (
                    <InquiryCardsHorizontal
                      type={CategoryType.PROPERTY}
                      isExhibition={false}
                      parentOrganizationId={
                        (product.organization as Organization)?.parentId || ''
                      }
                    />
                  )}
                </>
              )}
              {isMobile ? (
                <>
                  <PropertyInfoSp product={product} />
                  <OrganizationInfoSp product={product} />
                </>
              ) : (
                <>
                  <PropertyInfo product={product} />
                  <OrganizationInfo product={product} />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
