import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import React from 'react';

import {
  propertyLimitationValues,
  propertyOtherLimitationValues,
} from '@app/static/constants';
import { theme } from '@app/theme';
import { Product, ProductLegalOtherLimitationType } from '@app/types/catalog';

interface LegalLimitationInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

export function LegalLimitationInfo({ product }: LegalLimitationInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        制限事項
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>法令上の制限</TableCellWithStyle>
              <TableCell>
                {product.customFields.salesType && (
                  <>
                    <Typography component="span">
                      {(product.customFields.legalLimitations || [])
                        .map(
                          (limitationCode) =>
                            propertyLimitationValues[limitationCode]
                        )
                        .join('、')}
                    </Typography>
                    <br />
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>その他の制限事項</TableCellWithStyle>
              <TableCell>
                <Typography>
                  {product.customFields.legalOtherLimitations &&
                    product.customFields.legalOtherLimitations
                      .map(
                        (limitationCode) =>
                          propertyOtherLimitationValues[limitationCode]
                      )
                      .join('、')}
                </Typography>
                <Typography>
                  {
                    Object.values(ProductLegalOtherLimitationType).find(
                      (type) =>
                        type.key ===
                        product.customFields.legalOtherLimitationType
                    )?.label
                  }
                </Typography>
                <Typography>
                  {product.customFields.legalOtherLimitationNote}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
