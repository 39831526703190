import { AxiosPromise } from 'axios';

import { organizationInstance } from '@app/adapter/axios';
import { Pagination } from '@app/types/common';
import {
  Follow,
  FollowCreate,
  FollowerTypeKey,
  FollowTypeKey,
  Organization,
  StructureType,
} from '@app/types/organization';
import { getAuthorizationHeader } from '@app/utils/authorization';

export function getOrganizations(...ids: string[]) {
  const params = new URLSearchParams([['ids', ids.join(',')]]);
  return organizationInstance
    .get(`/organizations?${params.toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * 企業のみ取得
 */
export function getParentOrganizations(options?: {
  childrenIds?: string[];
  expand?: string;
  locationIds?: string[];
  nextLink?: string;
  organizationId?: string[];
  top?: number;
}) {
  const params = new URLSearchParams();

  const allOrganizationIds = options?.organizationId
    ? [...options.organizationId]
    : [];

  if (options?.childrenIds?.length) {
    allOrganizationIds.push(...options.childrenIds);
  }

  let filter = `customFields.structureType eq '${StructureType.PARENT}'`;

  if (allOrganizationIds.length) {
    const organizationIdFilter = allOrganizationIds
      .map((id) => `'${id}'`)
      .join(',');
    filter += ` and id in [${organizationIdFilter}]`;
  }

  if (options?.locationIds?.length) {
    const locationIdsFilter = options.locationIds
      .map((id) => `'${id}'`)
      .join(',');
    filter += ` and customFields.buildArea in [${locationIdsFilter}]`;
  }

  params.append('$filter', filter);

  if (options?.expand) {
    params.append('$expand', options.expand);
  }
  if (options?.nextLink) {
    return organizationInstance.get(options.nextLink);
  }

  params.append('$top', options?.top?.toString() || '20');
  params.append('$orderBy', 'createdAt desc');

  return organizationInstance
    .get(`/organizations?${params.toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * 特定の企業を親に持つOrganization(窓口)の取得
 */
export function getOrganizationsByParentIds(ids: string[]) {
  const filterSyntaxGen = (values: string[]) =>
    values.map((id) => `'${id}'`).join(',');
  const params = new URLSearchParams([
    ['$filter', `parentId in [${filterSyntaxGen(ids)}]`],
  ]);
  return organizationInstance
    .get(`/organizations?${params.toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganization(
  organizationId: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<Organization> {
  const urlParams = [];
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return organizationInstance
    .get(
      `/organizations/${organizationId}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * follow
 */
export function createFollow(
  organizationId: string,
  token: string,
  fingerPrint: string,
  payload: FollowCreate
): AxiosPromise<Organization> {
  return organizationInstance
    .post(`/organizations/${organizationId}/follow`, payload, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getFollows(
  token: string,
  fingerPrint: string,
  type: FollowerTypeKey,
  followerId: string,
  options?: {
    followId?: string;
    followType?: FollowTypeKey;
    limit?: number;
  }
): AxiosPromise<Pagination<Follow[]>> {
  const urlParams = [['$top', String(options?.limit ?? 100)]];
  urlParams.push(['type', type]);
  urlParams.push(['followerId', followerId]);

  const filterParams = [];
  if (options?.followType) {
    filterParams.push(`followType eq '${options.followType}'`);
  }
  if (options?.followId) {
    filterParams.push(`followId eq '${options.followId}'`);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return organizationInstance
    .get(`/follows?${new URLSearchParams(urlParams).toString()}`, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
