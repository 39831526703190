import { Typography } from '@mui/material';

interface TextPrettyProps {
  color?: string;
  formType: string;
  text?: string;
}

export function TextPretty({ text, color, formType }: TextPrettyProps) {
  const getForm = {
    boldBase: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
    },
    boldSm: {
      fontSize: '22px',
      fontWeight: '700',
      lineHeight: '34px',
    },
    lightBase: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    lightXs: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
    },
  }[formType];

  return (
    <Typography
      paragraph={true}
      sx={{
        color,
        marginBottom: '0px',
        minWidth: '0',
        overflowWrap: 'break-word',
        wordBreak: 'break-all',
        ...getForm,
      }}
    >
      {text}
    </Typography>
  );
}
