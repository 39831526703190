import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, Box, Button } from '@mui/material';
import React, { useState } from 'react';

import { theme } from '@app/theme';

interface CarouselItemProps {
  image: string;
}

export function CarouselItem({ image }: CarouselItemProps) {
  return (
    <Box
      sx={{
        borderRadius: 1,
        flexShrink: 0,
        mr: '12px',
        overflow: 'hidden',
        paddingTop: '10.5%',
        position: 'relative',
        width: 'calc((100% - 60px) / 6)',
      }}
    >
      <img
        src={image}
        alt="carousel item"
        style={{
          height: '100%',
          left: 0,
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      />
    </Box>
  );
}

interface ImageCarouselProps {
  displayMode: 'largeImage' | 'carousel';
  images: string[];
}

export function ImageCarousel({ images, displayMode }: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '24px !important',
      }}
    >
      {displayMode === 'largeImage' && (
        <Box
          sx={{
            borderRadius: 2,
            mb: 3,
            overflow: 'hidden',
            paddingTop: '66.6%',
            position: 'relative',
            width: '100%',
          }}
        >
          <img
            src={images[currentIndex]}
            alt="selected item"
            style={{
              borderRadius: 2,
              height: '100%',
              left: 0,
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          />
        </Box>
      )}

      {(displayMode === 'carousel' || displayMode === 'largeImage') && (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
          }}
          direction="row"
        >
          <Box sx={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                transform: `translateX(-${currentIndex * (100 / 6)}%)`,
                transition: 'transform 0.5s ease-in-out',
                width: '100%',
              }}
            >
              {images.map((image, index) => (
                <CarouselItem key={index} image={image} />
              ))}
            </Box>
            <Button
              onClick={handlePrev}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '50%',
                left: 0,
                minHeight: '40px',
                minWidth: '40px',
                padding: 0,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
              }}
            >
              <ArrowBackIcon sx={{ color: theme.palette.grey[800] }} />
            </Button>
            <Button
              onClick={handleNext}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '50%',
                minHeight: '40px',
                minWidth: '40px',
                padding: 0,
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
              }}
            >
              <ArrowForwardIcon sx={{ color: theme.palette.grey[800] }} />
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
