import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Grid, Typography, Box, Button } from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { RiHomeLine } from 'react-icons/ri';

import { ProductFeature } from '@app/types/catalog';

interface StrengthsSectionProps {
  features: ProductFeature[];
  showReadMore?: boolean;
  strengthsDescription: string;
  strengthsTitle: string;
}

interface ReadMoreProps {
  maxLines?: number;
  text: string;
}

interface OrganizationStrengthsSectionProps {
  features: string[];
  showReadMore?: boolean;
  strengthsDescription: string;
  strengthsTitle: string;
}

function ReadMore({ text, maxLines = 2 }: ReadMoreProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * maxLines;
      if (textRef.current.scrollHeight > maxHeight) {
        setIsTruncated(true);
      }
    }
  }, [maxLines, text]);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Box
        ref={textRef}
        sx={{
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isExpanded ? 'none' : maxLines,
          display: '-webkit-box',
          overflow: 'hidden',
        }}
      >
        <Typography variant="body2" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
          {text}
        </Typography>
      </Box>
      {isTruncated && (
        <Button onClick={handleToggle} sx={{ fontWeight: 300 }}>
          {isExpanded ? '表示を折りたたむ' : '続きを読む'}
        </Button>
      )}
    </div>
  );
}

export function StrengthsSection({
  features,
  strengthsTitle,
  strengthsDescription,
  showReadMore = true,
}: StrengthsSectionProps): ReactElement {
  return (
    <Box sx={{ width: 1 }}>
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        物件の特徴
      </Typography>
      <Grid container>
        {features
          .filter((feature) => feature.value)
          .map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              sx={{ alignItems: 'center', display: 'flex', my: 0.5 }}
            >
              <RiHomeLine style={{ marginRight: '8px' }} />
              <Typography variant="body2">{feature.value}</Typography>
            </Grid>
          ))}
      </Grid>
      <Typography variant="body2" fontWeight={600} sx={{ mt: 2 }}>
        {strengthsTitle}
      </Typography>
      {showReadMore ? (
        <ReadMore text={strengthsDescription} />
      ) : (
        <Typography variant="body2" sx={{ mt: 0, whiteSpace: 'pre-wrap' }}>
          {strengthsDescription}
        </Typography>
      )}
    </Box>
  );
}

export function OrganizationStrengthsSection({
  features = [],
  strengthsTitle,
  strengthsDescription,
  showReadMore = true,
}: OrganizationStrengthsSectionProps): ReactElement {
  return (
    <Box sx={{ width: 1 }}>
      <Grid container>
        {features
          .filter((feature) => feature)
          .map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              sx={{ alignItems: 'center', display: 'flex', my: 0.5 }}
            >
              <HomeOutlinedIcon style={{ marginRight: '8px' }} />
              <Typography variant="body2">{feature}</Typography>
            </Grid>
          ))}
      </Grid>
      <Typography variant="body2" fontWeight={600} sx={{ mt: 2 }}>
        {strengthsTitle}
      </Typography>
      {showReadMore ? (
        <ReadMore text={strengthsDescription} />
      ) : (
        <Typography variant="body2" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
          {strengthsDescription}
        </Typography>
      )}
    </Box>
  );
}
