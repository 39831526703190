import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

import { theme } from '@app/theme';
import {
  Product,
  ProductLotteryDateSeason,
  ProductSalesType,
} from '@app/types/catalog';
import { parseDateStringToDateObj } from '@app/utils/date';

interface SalesScheduleInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

export function SalesScheduleInfo({ product }: SalesScheduleInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        販売スケジュール
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>販売方式</TableCellWithStyle>
              <TableCell>
                {product.customFields.salesType && (
                  <>
                    <Typography component="span">
                      {
                        Object.values(ProductSalesType).find(
                          (type) => type.key === product.customFields.salesType
                        )?.label
                      }
                    </Typography>
                    <br />
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>
                {
                  Object.values(ProductSalesType).find(
                    (type) => type.key === product.customFields.salesType
                  )?.dateLabel
                }
              </TableCellWithStyle>
              <TableCell>
                {product.customFields.salesStartDate && (
                  <>
                    <Typography component="span">
                      {format(
                        parseDateStringToDateObj(
                          product.customFields.salesStartDate
                        ),
                        product.customFields.salesEndDate
                          ? 'yyyy年M月d日'
                          : 'yyyy年M月'
                      )}
                      {product.customFields.salesEndDate &&
                        format(
                          parseDateStringToDateObj(
                            product.customFields.salesEndDate
                          ),
                          ' ~ yyyy年M月d日'
                        )}{' '}
                      {
                        Object.values(ProductLotteryDateSeason).find(
                          (season) =>
                            season.key === product.customFields.salesDateSeason
                        )?.label
                      }
                    </Typography>
                    <br />
                  </>
                )}
              </TableCell>
            </TableRow>
            {product.customFields.lotteryDate && (
              <TableRow>
                <TableCellWithStyle>抽選日</TableCellWithStyle>
                <TableCell>
                  <Typography component="span">
                    {format(
                      parseDateStringToDateObj(
                        product.customFields.lotteryDate
                      ),
                      // NOTE:スラッシュ区切りで3要素ある場合は日付を含める
                      product.customFields.lotteryDate.split('/').length === 3
                        ? 'yyyy年M月d日'
                        : 'yyyy年M月'
                    )}{' '}
                    {
                      Object.values(ProductLotteryDateSeason).find(
                        (season) =>
                          season.key === product.customFields.lotteryDateSeason
                      )?.label
                    }
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCellWithStyle>販売スケジュールコメント</TableCellWithStyle>
              <TableCell>
                {product.customFields.salesScheduleComment && (
                  <>
                    <Typography component="span">
                      {product.customFields.salesScheduleComment}
                    </Typography>
                    <br />
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
