import { AxiosPromise, CanceledError } from 'axios';

import { authInstance, graphqlInstance } from '@app/adapter/axios';
import { IUserProfile } from '@app/types/user';

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

/**
 * Login use auth-service
 * @param email
 * @param password
 * @param fingerprint
 * @returns
 */
export function login(email: string, password: string): Promise<LoginResponse> {
  const query = `
    mutation Authenticate($email: String!, $password: String!) {
      authenticate(input: { email: $email, password: $password }) {
        ... on AuthToken {
          accessToken
          refreshToken
        }
        ... on UnauthorizedError {
          message
        }
      }
    }
  `;

  return graphqlInstance
    .post('', {
      query,
      variables: { email, password },
    })
    .then((response) => {
      const { data } = response;
      const result = data.data.authenticate;
      if (result.message) {
        throw new Error(result.message || 'Unauthorized');
      }
      return {
        accessToken: result.accessToken,
        refreshToken: result.refreshToken,
      };
    })
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if (
        error.response &&
        error.response.data &&
        'message' in error.response.data
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Check token
 * @param token
 * @returns
 */
export function checkToken<T>(
  token: string,
  fingerprint: string,
  abortController?: AbortController
): AxiosPromise<T> {
  return authInstance
    .post(
      '/check-token',
      { fingerprint, token },
      {
        signal: abortController?.signal,
      }
    )
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 *
 * @param token
 * @returns
 */
export function getProfileUser(token: string): Promise<IUserProfile> {
  const query = `
    query {
      userQuery {
        ... on UserQuery {
          currentUser {
            user {
              email
              displayName
              nameRuby
              profile {
                id
                position
              }
            }
            userId
            birthday {
              iso8601
            }
            gender {
              code
            }
            profile {
              ... on CurrentOwnerProfile {
                householdIncome
                phoneNumber
                postCode
                professionCode
                streetAddress
                workplace
              }
            }
          }
        }
      }
    }
  `;
  return graphqlInstance
    .post(
      '',
      {
        query,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data.data.userQuery.currentUser;
    })
    .catch((error) => {
      console.error('Get user error:', error);
      throw new Error('ユーザー情報の取得に失敗しました');
    });
}
