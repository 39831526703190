import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

import {
  LAND_RIGHT_TYPE_OPTIONS,
  PROPERTY_BUILDING_PERMIT_REASON_TYPE_VALUES,
} from '@app/static/constants';
import { theme } from '@app/theme';
import {
  Product,
  ProductCompletionDateType,
  ProductCompletionSeason,
  ProductDeliveryDateType,
  ProductDeliverySeason,
  ProductLandAreaType,
  ProductLandRightType,
  ProductRangeType,
  ProductReformExterior,
  ProductReformInterior,
  ProductSalesStatus,
  TransportMeans,
} from '@app/types/catalog';
import { parseDateStringToDateObj } from '@app/utils/date';
import {
  convertToTsubo,
  formatAreaRange,
  formatFloorPlan,
  formatPrice,
  formatPriceRange,
  getTransportMeanLabel,
  getTransportTimeLabel,
} from '@app/utils/format';

interface PropertyInfoSpProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

const TableCellWithFallback = ({
  value,
}: {
  value: string | number | undefined | null | string[];
}) => (
  <TableCell>
    {Array.isArray(value)
      ? value.join(', ')
      : value
      ? String(value)
          .split('\n')
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))
      : '-'}
  </TableCell>
);

export function PropertyInfoSp({ product }: PropertyInfoSpProps) {
  const getDeliverySeasonLabel = (seasonKey?: string) => {
    if (!seasonKey) return '';
    const season = Object.values(ProductDeliverySeason).find(
      (season) => season.key === seasonKey
    );
    return season ? `${season.label}` : '';
  };
  return (
    <Box sx={{ py: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        物件情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>販売状態</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {
                  Object.values(ProductSalesStatus).find(
                    (status) => status.key === product.customFields.salesStatus
                  )?.label
                }
              </TableCell>
            </TableRow>
            {product.customFields.salesStatus ===
              ProductSalesStatus.SCHEDULED_SALE.key && (
              <>
                <TableRow>
                  <TableCellWithStyle>予告広告催促コメント</TableCellWithStyle>
                </TableRow>
                <TableRow>
                  <TableCell>{product.customFields.previewAdComment}</TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCellWithStyle>所在地</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.addressLine1 + product.addressLine2}
                {product.customFields.addressLine3HiddenFlag === true &&
                  product.addressLine3}
              </TableCell>
            </TableRow>
            {product.customFields.transportMainLine && (
              <>
                <TableRow>
                  <TableCellWithStyle>主要交通</TableCellWithStyle>
                </TableRow>
                <TableRow>
                  <TableCellWithFallback
                    value={`${product.customFields.transportMainLine}「${
                      product.customFields.transportMainNearest
                    }」まで${getTransportMeanLabel(
                      product.customFields.transportMainMean
                    )}${getTransportTimeLabel(
                      product.customFields.transportMainMean,
                      product.customFields.transportMainTimeMin,
                      product.customFields.transportMainTimeMax,
                      product.customFields.transportMainNote
                    )}`}
                  />
                </TableRow>
              </>
            )}
            {product.customFields.transportSub1Line && (
              <>
                <TableRow>
                  <TableCellWithStyle>交通1</TableCellWithStyle>
                </TableRow>
                <TableRow>
                  <TableCellWithFallback
                    value={`${product.customFields.transportSub1Line}「${
                      product.customFields.transportSub1Nearest
                    }」まで${getTransportMeanLabel(
                      product.customFields.transportSub1Mean
                    )}${getTransportTimeLabel(
                      product.customFields.transportSub1Mean,
                      product.customFields.transportSub1TimeMin,
                      product.customFields.transportSub1TimeMax,
                      product.customFields.transportSub1Note
                    )}`}
                  />
                </TableRow>
              </>
            )}
            {product.customFields.transportSub2Line && (
              <>
                <TableRow>
                  <TableCellWithStyle>交通2</TableCellWithStyle>
                </TableRow>
                <TableRow>
                  <TableCellWithFallback
                    value={`${product.customFields.transportSub2Line}「${
                      product.customFields.transportSub2Nearest
                    }」まで${getTransportMeanLabel(
                      product.customFields.transportSub2Mean
                    )}${getTransportTimeLabel(
                      product.customFields.transportSub2Mean,
                      product.customFields.transportSub2TimeMin,
                      product.customFields.transportSub2TimeMax,
                      product.customFields.transportSub2Note
                    )}`}
                  />
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCellWithStyle>最寄り駅からの時間</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={(() => {
                  const times = [
                    product.customFields.transportMainMean ===
                    TransportMeans.WALK
                      ? product.customFields.transportMainTimeMin
                      : null,
                    product.customFields.transportSub1Mean ===
                    TransportMeans.WALK
                      ? product.customFields.transportSub1TimeMin
                      : null,
                    product.customFields.transportSub2Mean ===
                    TransportMeans.WALK
                      ? product.customFields.transportSub2TimeMin
                      : null,
                  ].filter(
                    (time): time is number =>
                      time !== null && time !== undefined && time > 0
                  );
                  const minTime = times.length > 0 ? Math.min(...times) : null;
                  return minTime !== null ? `${minTime}分以内` : '-';
                })()}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>販売戸数</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={
                  product.customFields.salesHouseCount
                    ? `${product.customFields.salesHouseCount}戸`
                    : '未定'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>総戸数</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={
                  product.customFields.totalHouseCount
                    ? `${product.customFields.totalHouseCount}戸`
                    : '未定'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>価格</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {formatPriceRange(
                    product.customFields.minPrice
                      ? Number(product.customFields.minPrice)
                      : undefined,
                    product.customFields.maxPrice
                      ? Number(product.customFields.maxPrice)
                      : undefined,
                    formatPrice,
                    product.customFields.priceRangeType
                  )}
                </Typography>
                <Typography>{product.customFields.priceAdditional}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>最多価格帯</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {product.customFields.mostPriceRanges &&
                  product.customFields.mostPriceRanges.length > 0
                    ? product.customFields.mostPriceRanges
                        .filter((range) => range !== '')
                        .map((range) => `${range}万円台`)
                        .join('、')
                    : '-'}
                  <Typography component="span">
                    {product.customFields.mostPriceRangeCount &&
                      `(${product.customFields.mostPriceRangeCount}戸)`}
                  </Typography>
                </Typography>
                <Typography>
                  {product.customFields.mostPriceRangeAdditional}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>私道負担・道路</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.minRoadWidth &&
                  `道路幅：${product.customFields.minRoadWidth}m`}

                {product.customFields.roadWidthRangeType &&
                  product.customFields.maxRoadWidth && (
                    <>
                      {product.customFields.roadWidthRangeType ===
                      ProductRangeType.BETWEEN
                        ? '〜'
                        : product.customFields.roadWidthRangeType ===
                          ProductRangeType.SEPARATE
                        ? '・'
                        : ' '}
                    </>
                  )}
                {product.customFields.maxRoadWidth &&
                  `${product.customFields.maxRoadWidth}m`}
                {(product.customFields.minRoadWidth ||
                  product.customFields.maxRoadWidth) &&
                  product.customFields.roadPainting &&
                  '、'}
                {product.customFields.roadPainting}
                {product.customFields.roadNote &&
                  `、${product.customFields.roadNote}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>諸費用</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>{product.customFields.facilityCost}</Typography>
                <Typography>{product.customFields.otherCost}</Typography>
                <Typography>{product.customFields.priceNote}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>間取りタイプ</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {formatFloorPlan(
                    product.customFields.floorPlanRooms,
                    product.customFields.floorPlanTypeMin,
                    product.customFields.floorPlanTypeMax,
                    product.customFields.floorPlanRangeType
                  )}
                </Typography>
                <Typography color="gray">
                  {product.customFields.floorPlanTypeAdditional}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>建物面積</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.buildingAreaType &&
                  Object.values(ProductLandAreaType).find(
                    (type) => type.key === product.customFields.buildingAreaType
                  )?.label}
                {formatAreaRange(
                  product.customFields.minBuildingArea,
                  product.customFields.maxBuildingArea,
                  product.customFields.buildingAreaRangeType
                )}
                {product.customFields.tsuboNotationFlag && (
                  <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                    （
                    {convertToTsubo(
                      Number(product.customFields.minBuildingArea)
                    )}
                    坪
                    {product.customFields.maxBuildingArea
                      ? `〜${convertToTsubo(
                          Number(product.customFields.maxBuildingArea)
                        )}坪`
                      : ' '}
                    ）
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>土地面積</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.landAreaType &&
                  Object.values(ProductLandAreaType).find(
                    (type) => type.key === product.customFields.landAreaType
                  )?.label}
                {formatAreaRange(
                  product.customFields.minLandArea,
                  product.customFields.maxLandArea,
                  product.customFields.landAreaRangeType
                )}
                {product.customFields.tsuboNotationFlag && (
                  <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                    （{convertToTsubo(Number(product.customFields.minLandArea))}
                    坪
                    {product.customFields.maxLandArea
                      ? `〜${convertToTsubo(
                          Number(product.customFields.maxLandArea)
                        )}坪`
                      : ' '}
                    ）
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>建ペイ率・容積率</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback value={product.customFields.areaRatio} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>完成時期（築年月）</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.completionDateType &&
                  Object.values(ProductCompletionDateType).find(
                    (type) =>
                      type.key === product.customFields.completionDateType
                  )?.label}{' '}
                {product.customFields.completionDateType !==
                ProductCompletionDateType.CONTRACTED.key
                  ? format(
                      product.customFields?.completionDate
                        ? parseDateStringToDateObj(
                            product.customFields.completionDate
                          )
                        : new Date(),
                      'yyyy年M月'
                    )
                  : `${product.customFields.completionDate}ヶ月`}
                {product.customFields.completionSeason &&
                  Object.values(ProductCompletionSeason).find(
                    (type) => type.key === product.customFields.completionSeason
                  )?.label}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>引き渡し可能時期</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {product.customFields.deliveryDateType &&
                    (() => {
                      const deliveryType = Object.values(
                        ProductDeliveryDateType
                      ).find(
                        (type) =>
                          type.key === product.customFields.deliveryDateType
                      );
                      if (
                        deliveryType?.key === 'specific' &&
                        product.customFields.deliveryDate
                      ) {
                        const formattedDate = format(
                          parseDateStringToDateObj(
                            product.customFields.deliveryDate
                          ),
                          'yyyy年M月'
                        );
                        const seasonLabel = getDeliverySeasonLabel(
                          product.customFields.deliverySeason
                        );
                        return `${formattedDate}${seasonLabel}`;
                      } else if (deliveryType?.key === 'contracted') {
                        return `契約後 ${product.customFields.deliveryDate}ヶ月`;
                      }
                      return deliveryType?.label || '';
                    })()}
                </Typography>
                <Typography>{product.customFields.deliveryNote}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>土地の権利形態</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {
                  LAND_RIGHT_TYPE_OPTIONS.find(
                    (option) =>
                      option.value === product.customFields.landRightType
                  )?.label
                }
                {product.customFields.landRightType !==
                  ProductLandRightType.OWNERSHIP && (
                  <>
                    、その期間等：
                    {product.customFields.landLeaseholdDuration || '-'}
                    、その月賃料：
                    {product.customFields.landLeaseholdRent || '-'}
                    、借地権の場合その他の費用：
                    {product.customFields.landLeaseholdCost || '-'}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>構造・工法</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={product.customFields.buildingSystem}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>施工</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={product.customFields.constructionCompany}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>リフォーム</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  外装：
                  {Object.values(ProductReformExterior).find(
                    (type) => type.key === product.customFields.reformExterior
                  )?.label || '-'}
                </Typography>

                <Typography>
                  内装：
                  {Object.values(ProductReformInterior).find(
                    (type) => type.key === product.customFields.reformInterior
                  )?.label || '-'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>用途地域</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {product.customFields.useDistrict &&
                    product.customFields.useDistrict
                      .filter((district) => district !== '')
                      .join('、')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.customFields.buildingPermitReasonType &&
                    PROPERTY_BUILDING_PERMIT_REASON_TYPE_VALUES[
                      product.customFields.buildingPermitReasonType
                    ]}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>地目</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.landCategory ||
                product.customFields.buildingPermitReason ? (
                  <>
                    <Typography>{product.customFields.landCategory}</Typography>
                    <Typography>
                      {product.customFields.buildingPermitReason}
                    </Typography>
                  </>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>その他概要・特記事項</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.facility && (
                  <Typography>設備:{product.customFields.facility}</Typography>
                )}
                {product.customFields.buildingConfirmationNumber && (
                  <Typography>
                    建築確認番号:
                    {product.customFields.buildingConfirmationNumber}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
