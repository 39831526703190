export interface Message {
  category: string;
  content: string;
  customFields?: Record<string, unknown>;
  id: string;
  priority: string;
  publications: {
    since?: number;
    until?: number;
  };
  senderId: string;
  senderType: string;
  title: string;
  topicId: string;
  typeId: string;
}

export interface MessageListState {
  data: Message[];
  nextLink: string;
  totalItem: number;
}

export const MESSAGE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};
