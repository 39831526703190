import { Box, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  link?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export function Breadcrumb({ items }: BreadcrumbProps): ReactElement {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      color="gray.700"
      fontSize="0.875rem"
      sx={{ mt: 3, mx: 4 }}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <Typography component="span" mx={2} color={theme.palette.grey[500]}>
              {'>'}
            </Typography>
          )}
          {item.link ? (
            <Link to={item.link} style={{ textDecoration: 'none' }}>
              <Typography component="span" color="primary.dark">
                {item.label}
              </Typography>
            </Link>
          ) : (
            <Typography component="span" color={theme.palette.grey[500]}>
              {item.label}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
