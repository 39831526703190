import {
  Box,
  Container,
  Divider,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { getPublicNotifications } from '@app/adapter/notification-service';
import { convertNewLineToBr } from '@app/components/Shared/Helpers';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import { SimpleDialog } from '@app/components/Shared/SimpleDialog';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { theme } from '@app/theme';
import { Message, MESSAGE_STATUS, MessageListState } from '@app/types/message';
import { isError } from '@app/utils/error';
import { restoreSanitizedString, timestampFormatJp } from '@app/utils/format';

export function Notification(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const setSnackbar = useSetSnackbar();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Message>();
  // Table data
  const [notificationData, setNotificationData] = useState<MessageListState>({
    data: [],
    nextLink: '',
    totalItem: 0,
  });
  const handleRowClick = (message: Message) => {
    setSelectedItem(message);
    setIsShowDialog(true);
  };

  const fetchNotifications = useCallback(
    async (nextLink?: string) => {
      try {
        const { data } = await getPublicNotifications({
          filter: { status: MESSAGE_STATUS.PUBLISHED },
          nextLink,
        });
        setNotificationData({
          data: nextLink
            ? [...notificationData.data, ...data.value]
            : data.value,
          nextLink: data['@nextLink'] || '',
          totalItem: data.total,
        });
      } catch (e) {
        if (isError(e)) {
          setSnackbar(true, e.message);
        }
      }
    },
    [notificationData.data, setSnackbar]
  );

  const handleLoadMore = () => {
    if (notificationData.nextLink) {
      void fetchNotifications(notificationData.nextLink);
    }
  };

  const initData = async () => {
    setIsLoading(true);
    await fetchNotifications();
    setIsLoading(false);
  };
  useEffect(() => {
    void initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Container sx={{ pt: 3 }}>
        <Typography variant="h3" fontWeight={700} my={3}>
          お知らせ
        </Typography>
        <MenuList sx={{ pb: 4 }}>
          <MenuItem
            component="div"
            sx={{
              backgroundColor: theme.palette.grey[300],
              color: theme.palette.text.primary,
              opacity: '1 !important',
              py: 1.6,
            }}
            disabled
          >
            <Typography mr={4} width={140}>
              日付
            </Typography>
            <Typography>タイトル</Typography>
          </MenuItem>
          {notificationData.data.map((message: Message) => (
            <Box
              component="li"
              key={message.id}
              onClick={() => handleRowClick(message)}
            >
              <Divider sx={{ my: '0 !important' }} />
              <MenuItem component="div" key={message.id} sx={{ py: 1.6 }}>
                <Typography mr={4} width={140}>
                  {timestampFormatJp(message.publications.since)}
                </Typography>
                <Typography>{message.title}</Typography>
              </MenuItem>
            </Box>
          ))}
          <Divider sx={{ my: '0 !important' }} />
        </MenuList>
      </Container>
      <ScrollThreshold
        disabled={!notificationData.nextLink}
        thresholdReached={handleLoadMore}
      />
      <SimpleDialog
        open={isShowDialog}
        title={selectedItem?.title}
        content={
          selectedItem?.content
            ? convertNewLineToBr(restoreSanitizedString(selectedItem.content))
            : ''
        }
        onClickCancel={() => setIsShowDialog(false)}
      />
    </>
  );
}
