import {
  Card as MuiCard,
  CardMedia,
  CardContent,
  Typography,
  Link,
  Box,
} from '@mui/material';
import React, { ReactElement } from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import { Attachment, ProductRangeTypeKey } from '@app/types/catalog';
import { formatPrice, formatPriceRange } from '@app/utils/format';

interface CardProps {
  address: string;
  imageSrc: string;
  isProperty?: boolean;
  maxPrice?: number;
  minPrice?: number;
  organizationId?: string;
  priceRangeType?: ProductRangeTypeKey;
  productId?: string;
  title: string;
}

export function ExhibitionCard({
  imageSrc,
  title,
  address,
  organizationId,
  productId,
}: CardProps): ReactElement {
  const { isMobile } = useDeviceType();

  return (
    <Link
      href={
        productId ? `/exhibitions/${productId}` : `/companies/${organizationId}`
      }
      underline="none"
    >
      <MuiCard sx={{ borderRadius: 2, boxShadow: 3, width: 350 }}>
        <CardContent sx={isMobile ? { px: 0 } : undefined}>
          <Box
            sx={{
              backgroundImage: `url(${imageSrc})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: '8px',
              height: '200px',
              mx: 'auto',
              width: isMobile ? '100%' : '95%',
            }}
          />
          <Box
            sx={{
              mx: 'auto',
              paddingTop: '16px',
              width: isMobile ? '100%' : '95%',
            }}
          >
            <Typography variant="subtitle2" component="div" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address}
            </Typography>
          </Box>
        </CardContent>
      </MuiCard>
    </Link>
  );
}

export function PropertyCard({
  imageSrc,
  title,
  address,
  minPrice,
  productId,
  maxPrice,
  priceRangeType,
}: CardProps): ReactElement {
  const { isMobile } = useDeviceType();

  return (
    <Link href={`/properties/${productId}`} underline="none">
      <MuiCard sx={{ borderRadius: 2, boxShadow: 3, width: 320 }}>
        <CardContent sx={isMobile ? { px: 0 } : undefined}>
          <Box
            sx={{
              backgroundImage: `url(${imageSrc})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: '8px',
              height: '200px',
              mx: 'auto',
              width: isMobile ? '100%' : '95%',
            }}
          />
          <Box
            sx={{
              mx: 'auto',
              paddingTop: '16px',
              width: isMobile ? '100%' : '95%',
            }}
          >
            <Typography
              variant="subtitle2"
              component="div"
              fontWeight="bold"
              color="primary"
            >
              {title}
            </Typography>
            <Typography variant="subtitle2" component="div" fontWeight="bold">
              {formatPriceRange(
                minPrice ? Number(minPrice) : undefined,
                maxPrice ? Number(maxPrice) : undefined,
                formatPrice,
                priceRangeType
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address}
            </Typography>
          </Box>
        </CardContent>
      </MuiCard>
    </Link>
  );
}

interface InquiryPropertyCardProps {
  access?: string;
  imageSrc: Attachment[];
  isProperty?: boolean;
  maxPrice?: number;
  minPrice?: number;
  name: string;
  priceRangeType?: ProductRangeTypeKey;
}

export function InquiryPropertyCard({
  imageSrc,
  name,
  access,
  minPrice,
  maxPrice,
  priceRangeType,
}: InquiryPropertyCardProps): ReactElement {
  const imageUrl = imageSrc && imageSrc.length > 0 ? imageSrc[0].url : '';
  const imageAlt =
    imageSrc && imageSrc.length > 0 ? imageSrc[0].type : '物件画像';

  return (
    <MuiCard
      sx={{
        border: `1px solid ${theme.palette.neutral.silver}`,
        borderRadius: 2,
        boxShadow: 3,
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        width: 750,
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={imageUrl}
        alt={imageAlt}
        sx={{ m: 2, width: { md: 250, xs: 'unset' } }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="subtitle2" fontWeight="bold">
          {name}
        </Typography>
        <Typography>
          {formatPriceRange(
            minPrice ? Number(minPrice) : undefined,
            maxPrice ? Number(maxPrice) : undefined,
            formatPrice,
            priceRangeType
          )}
        </Typography>
        <Typography color="text.secondary">{access}</Typography>
      </CardContent>
    </MuiCard>
  );
}
