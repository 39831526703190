import FlagIcon from '@mui/icons-material/Flag';
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  useTheme,
  Stack,
} from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';

import { searchConditionState } from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { CategoryType } from '@app/types/catalog';
interface InquiryCardsProps {
  handleScrollToExhibitionProductList: () => void;
}
export function InquiryCards({
  handleScrollToExhibitionProductList,
}: InquiryCardsProps): ReactElement {
  const resetSearchConditionState = useResetRecoilState(searchConditionState);
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { isMobile } = useDeviceType();
  const child = searchParams.get('child')?.split(',') ?? [];

  const theme = useTheme();
  return (
    <Box
      sx={{
        maxWidth: isMobile ? '100vw' : 300,
        ml: isMobile ? 0 : 3,
        width: '100%',
      }}
    >
      <Card
        sx={{
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: '8px',
          mb: 2,
          px: 2,
        }}
      >
        <CardContent sx={{ mb: 2, px: 0 }}>
          <Typography variant="body2" fontWeight="bold" mb={3}>
            この企業の住宅展示場を見学
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: theme.palette.grey[400],
              borderRadius: 1,
              color: 'black !important',
              fontWeight: 'normal',
              p: 1,
            }}
            fullWidth
            onClick={handleScrollToExhibitionProductList}
          >
            住宅展示場を探す
          </Button>
        </CardContent>

        <Divider />

        <CardContent sx={{ mt: 1, px: 0 }}>
          <Typography variant="body2" fontWeight="bold" mb={3}>
            この企業の分譲を探す
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: theme.palette.grey[400],
              borderRadius: 1,
              color: 'black !important',
              fontWeight: 'normal',
              p: 1,
            }}
            fullWidth
            onClick={() => {
              resetSearchConditionState();
              navigate(
                `/properties?org=${organizationId}&category=${CategoryType.PROPERTY}&child=${child}`
              );
            }}
          >
            分譲を探す
          </Button>
        </CardContent>
      </Card>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <FlagIcon color="disabled" fontSize="small" />
        <Typography variant="body1" color={theme.palette.grey[500]} ml={1}>
          Report listing
        </Typography>
      </Stack>
    </Box>
  );
}
