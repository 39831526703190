import { Box, Container, Grid, Divider } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProduct } from '@app/adapter/catalog-service';
import { ExhibitionFloorPlan } from '@app/components/Product/ExhibitionFloorPlan';
import { ExhibitionInfo } from '@app/components/Product/ExhibitionInfo';
import { ExhibitionInfoSp } from '@app/components/Product/ExhibitionInfoSp';
import { InquiryCardsHorizontal } from '@app/components/Product/InquiryCards';
import { ProductDetails } from '@app/components/Product/ProductDetails';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { CategoryType, Product, ProductVariantSku } from '@app/types/catalog';
import { Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';

export function ExhibitionPreview(): ReactElement {
  const { productId } = useParams();
  const setSnackbar = useSetSnackbar();
  const [product, setProduct] = useState<Product | null>(null);
  const { isMobile } = useDeviceType();

  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      const result = await getProduct(productId);
      setProduct(result.data);
    } catch (e) {
      if (isError(e)) {
        console.error('fetchProduct', e.message);
      }
      setSnackbar(true, '住宅展示場が見つかりません');
    }
  }, [productId, setSnackbar]);

  useEffect(() => {
    void fetchProduct();
    // eslint-disable-next-line
  }, []);

  if (!product) {
    return <Loading />;
  }

  const floorPlanVariants = product.variants.filter(
    (variant) => variant.sku === ProductVariantSku.FLOOR_PLAN
  );

  return (
    <>
      <HeadBlock isNoIndex />
      <Box>
        <Container sx={{ mx: 'auto' }} maxWidth="xl" disableGutters>
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12} md={9} sx={{ width: '100%' }}>
              <ProductDetails product={product} />
              {floorPlanVariants && floorPlanVariants.length > 0 ? (
                <ExhibitionFloorPlan variants={floorPlanVariants} />
              ) : (
                <Divider sx={{ my: 8 }} />
              )}
              {isMobile ? (
                <ExhibitionInfoSp product={product} />
              ) : (
                <ExhibitionInfo product={product} />
              )}
              {product && product.organizationId && (
                <InquiryCardsHorizontal
                  type={CategoryType.EXHIBITION}
                  isExhibition={true}
                  organizationId={product.organizationId}
                  parentOrganizationId={
                    (product.organization as Organization).parentId || ''
                  }
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
