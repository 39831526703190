import { AxiosPromise } from 'axios';

import { axiosInstance, notificationInstance } from '@app/adapter/axios';
import { GetListDataRequest, Pagination } from '@app/types/common';
import { Message } from '@app/types/message';

/**
 * Get public message list
 * @param token
 * @param fingerPrint
 * @returns
 */
export function getPublicNotifications(
  options?: GetListDataRequest & {
    filter?: {
      searchKeyword?: string;
      status?: string;
    };
  }
): AxiosPromise<Pagination<Message[]>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 20;
  const urlParams = [['$top', pageSize.toString()]];

  const filterParam = [];
  if (options?.filter?.status) {
    filterParam.push(`customFields.status eq '${options.filter.status}'`);
  }
  urlParams.push([
    '$orderBy',
    options?.orderBy || 'publications.since desc,createdAt desc',
  ]);

  if (options?.filter?.searchKeyword) {
    const queryFields = ['id', 'title'];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.searchKeyword}'`)
      .join(' or ');
    filterParam.push(query);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }

  return notificationInstance
    .get(`/public/messages?${new URLSearchParams(urlParams).toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
