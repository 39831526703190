import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  Stack,
} from '@mui/material';
import { ReactElement, useState, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  getOrganizationsByParentIds,
  getParentOrganizations,
} from '@app/adapter/organization-service';
import { locationsTreeSelector } from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { ACCESS_OPTIONS, ROOM_TYPE_OPTIONS } from '@app/static/constants';
import { Conditions } from '@app/types/catalog';
import { Organization } from '@app/types/organization';

interface SearchConditionProps {
  condition?: Conditions;
  onSearch: (conditions: Conditions) => void;
  showPropertySearch: boolean;
}

const defaultCondition: Conditions = {
  locationIds: [],
  organizationId: [],
};

export function SearchCondition({
  condition = defaultCondition,
  onSearch,
  showPropertySearch,
}: SearchConditionProps): ReactElement {
  const theme = useTheme();
  const locations = useRecoilValue(locationsTreeSelector);
  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    condition.locationIds
  );
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>(
    condition.organizationId || []
  );
  const [childrenIds, setChildrenIds] = useState<string[]>([]);
  const { isMobile } = useDeviceType();

  // 分譲関連のState
  const [minPrice, setPriceMin] = useState<number | undefined>(
    condition.minPrice || undefined
  );
  const [maxPrice, setPriceMax] = useState<number | undefined>(
    condition.maxPrice || undefined
  );
  const [minLandArea, setLandAreaMin] = useState<number | undefined>(
    condition.minLandArea || undefined
  );
  const [maxLandArea, setLandAreaMax] = useState<number | undefined>(
    condition.maxLandArea || undefined
  );
  const [minBuildingArea, setBuildingAreaMin] = useState<number | undefined>(
    condition.minBuildingArea || undefined
  );
  const [maxBuildingArea, setBuildingAreaMax] = useState<number | undefined>(
    condition.maxBuildingArea || undefined
  );
  const [access, setAccess] = useState<number | undefined>(
    condition.access || undefined
  );

  const [floorPlanRooms, setFloorPlanRooms] = useState<number[]>(
    condition.floorPlanRooms ?? []
  );
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchChildrenIds = useCallback(async (organizationId: string) => {
    try {
      const response = await getOrganizationsByParentIds([organizationId]);
      const childIds =
        response.data.value.map((child: Organization) => child.id) || [];
      return childIds;
    } catch (error) {
      console.error('Error fetching child organizations:', error);
      return [];
    }
  }, []);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await getParentOrganizations();
      setOrganizations(response.data.value);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }, []);

  useEffect(() => {
    void fetchOrganizations();
  }, [fetchOrganizations]);

  const handleLocationChange = useCallback(
    (id: string, childrenIds: string[] = []) => {
      setSelectedLocations((prev) => {
        if (prev.includes(id)) {
          return prev.filter(
            (locationId) =>
              locationId !== id && !childrenIds.includes(locationId)
          );
        }
        return [...prev, id, ...childrenIds];
      });
    },
    []
  );

  const handleCompanyChange = useCallback(
    async (id: string) => {
      setSelectedCompanies((prev) => {
        if (prev.includes(id)) {
          return prev.filter((companyId) => companyId !== id);
        }
        return [...prev, id];
      });

      if (showPropertySearch) {
        const childIds = await fetchChildrenIds(id);
        setChildrenIds((prev) => {
          if (prev.some((childId) => childIds.includes(childId))) {
            return prev.filter((childId) => !childIds.includes(childId));
          }
          return [...prev, ...childIds];
        });
      }
    },
    [fetchChildrenIds, showPropertySearch]
  );

  const clearAllConditions = () => {
    setSelectedLocations([]);
    setSelectedCompanies([]);
    setFloorPlanRooms([]);
    setPriceMin(undefined);
    setPriceMax(undefined);
    setLandAreaMin(undefined);
    setLandAreaMax(undefined);
    setBuildingAreaMin(undefined);
    setBuildingAreaMax(undefined);
    setAccess(undefined);
    setChildrenIds([]);
  };

  const handleRoomTypeChange = (value: number) => {
    setFloorPlanRooms((prev) => {
      if (prev.includes(value)) {
        return prev.filter((t) => t !== value);
      }
      return [...prev, value];
    });
  };

  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: '16px',
        m: 2,
        ml: 4,
        width: isMobile ? 'calc(100vw - 64px)' : 320,
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Typography
          variant="subtitle1"
          component="div"
          textAlign="center"
          color={theme.palette.primary.dark}
          py={1}
          sx={{ backgroundColor: '#e3f2fd', fontWeight: 'bold' }}
        >
          検索条件
        </Typography>
        <Divider />
        <Box sx={{ m: 2 }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: 'bold', pt: 1 }}
          >
            建築エリアを選ぶ
          </Typography>
          {!!locations.length &&
            locations.map((region) => (
              <Accordion
                key={region.id}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      value={region.id}
                      checked={region.children?.every((child) =>
                        selectedLocations.includes(child.id)
                      )}
                      indeterminate={
                        region.children?.some((child) =>
                          selectedLocations.includes(child.id)
                        ) &&
                        !region.children?.every((child) =>
                          selectedLocations.includes(child.id)
                        )
                      }
                      onClick={(e) => e.stopPropagation()}
                      onChange={() =>
                        handleLocationChange(
                          region.id,
                          region.children
                            ? region.children.map((child) => child.id)
                            : []
                        )
                      }
                    />
                    <Typography>{region.name}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 5, py: 1 }}>
                  <Stack>
                    {region.children &&
                      region.children.map((prefecture) => (
                        <FormControlLabel
                          key={prefecture.id}
                          label={prefecture.name}
                          control={
                            <Checkbox
                              value={prefecture.id}
                              checked={selectedLocations.includes(
                                prefecture.id
                              )}
                              onChange={() =>
                                handleLocationChange(prefecture.id)
                              }
                            />
                          }
                        />
                      ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          {!showPropertySearch && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                企業を選ぶ
              </Typography>
              <Stack sx={{ flexDirection: 'column', ml: 3 }}>
                {organizations.slice(0, 10).map((organization) => (
                  <FormControlLabel
                    key={organization.id}
                    control={
                      <Checkbox
                        checked={selectedCompanies.includes(organization.id)}
                        onChange={() => handleCompanyChange(organization.id)}
                      />
                    }
                    label={organization.name}
                  />
                ))}
              </Stack>
            </>
          )}
          {showPropertySearch && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                価格
              </Typography>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={
                      minPrice !== undefined ? String(minPrice) : '下限なし'
                    }
                    onChange={(event: SelectChangeEvent) =>
                      setPriceMin(
                        event.target.value === '下限なし'
                          ? undefined
                          : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="下限なし">下限なし</MenuItem>
                    <MenuItem value="500">500万円以上</MenuItem>
                    <MenuItem value="1000">1000万円以上</MenuItem>
                    <MenuItem value="1500">1500万円以上</MenuItem>
                    <MenuItem value="2000">2000万円以上</MenuItem>
                    <MenuItem value="2500">2500万円以上</MenuItem>
                    <MenuItem value="3000">3000万円以上</MenuItem>
                    <MenuItem value="3500">3500万円以上</MenuItem>
                    <MenuItem value="4000">4000万円以上</MenuItem>
                    <MenuItem value="4500">4500万円以上</MenuItem>
                    <MenuItem value="5000">5000万円以上</MenuItem>
                    <MenuItem value="5500">5500万円以上</MenuItem>
                    <MenuItem value="6000">6000万円以上</MenuItem>
                    <MenuItem value="6500">6500万円以上</MenuItem>
                    <MenuItem value="7000">7000万円以上</MenuItem>
                    <MenuItem value="7500">7500万円以上</MenuItem>
                    <MenuItem value="8000">8000万円以上</MenuItem>
                    <MenuItem value="8500">8500万円以上</MenuItem>
                    <MenuItem value="9000">9000万円以上</MenuItem>
                    <MenuItem value="9500">9500万円以上</MenuItem>
                    <MenuItem value="10000">1億円以上</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ mx: 1 }}>~</Typography>
                <FormControl fullWidth>
                  <Select
                    value={
                      maxPrice !== undefined ? String(maxPrice) : '上限なし'
                    }
                    onChange={(event: SelectChangeEvent) =>
                      setPriceMax(
                        event.target.value === '上限なし'
                          ? undefined
                          : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="上限なし">上限なし</MenuItem>
                    <MenuItem value="500">500万円未満</MenuItem>
                    <MenuItem value="1000">1000万円未満</MenuItem>
                    <MenuItem value="1500">1500万円未満</MenuItem>
                    <MenuItem value="2000">2000万円未満</MenuItem>
                    <MenuItem value="2500">2500万円未満</MenuItem>
                    <MenuItem value="3000">3000万円未満</MenuItem>
                    <MenuItem value="3500">3500万円未満</MenuItem>
                    <MenuItem value="4000">4000万円未満</MenuItem>
                    <MenuItem value="4500">4500万円未満</MenuItem>
                    <MenuItem value="5000">5000万円未満</MenuItem>
                    <MenuItem value="5500">5500万円未満</MenuItem>
                    <MenuItem value="6000">6000万円未満</MenuItem>
                    <MenuItem value="6500">6500万円未満</MenuItem>
                    <MenuItem value="7000">7000万円未満</MenuItem>
                    <MenuItem value="7500">7500万円未満</MenuItem>
                    <MenuItem value="8000">8000万円未満</MenuItem>
                    <MenuItem value="8500">8500万円未満</MenuItem>
                    <MenuItem value="9000">9000万円未満</MenuItem>
                    <MenuItem value="9500">9500万円未満</MenuItem>
                    <MenuItem value="10000">1億円未満</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                土地面積
              </Typography>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={
                      minLandArea !== undefined
                        ? String(minLandArea)
                        : '下限なし'
                    }
                    onChange={(event: SelectChangeEvent) =>
                      setLandAreaMin(
                        event.target.value === '下限なし'
                          ? undefined
                          : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="下限なし">下限なし</MenuItem>
                    <MenuItem value="40">40㎡以上</MenuItem>
                    <MenuItem value="50">50㎡以上</MenuItem>
                    <MenuItem value="60">60㎡以上</MenuItem>
                    <MenuItem value="70">70㎡以上</MenuItem>
                    <MenuItem value="80">80㎡以上</MenuItem>
                    <MenuItem value="90">90㎡以上</MenuItem>
                    <MenuItem value="100">100㎡以上</MenuItem>
                    <MenuItem value="110">110㎡以上</MenuItem>
                    <MenuItem value="120">120㎡以上</MenuItem>
                    <MenuItem value="130">130㎡以上</MenuItem>
                    <MenuItem value="140">140㎡以上</MenuItem>
                    <MenuItem value="150">150㎡以上</MenuItem>
                    <MenuItem value="160">160㎡以上</MenuItem>
                    <MenuItem value="170">170㎡以上</MenuItem>
                    <MenuItem value="180">180㎡以上</MenuItem>
                    <MenuItem value="190">190㎡以上</MenuItem>
                    <MenuItem value="200">200㎡以上</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ mx: 1 }}>~</Typography>
                <FormControl fullWidth>
                  <Select
                    value={
                      maxLandArea !== undefined
                        ? String(maxLandArea)
                        : '上限なし'
                    }
                    onChange={(event: SelectChangeEvent) =>
                      setLandAreaMax(
                        event.target.value === '上限なし'
                          ? undefined
                          : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="上限なし">上限なし</MenuItem>
                    <MenuItem value="40">40㎡未満</MenuItem>
                    <MenuItem value="50">50㎡未満</MenuItem>
                    <MenuItem value="60">60㎡未満</MenuItem>
                    <MenuItem value="70">70㎡未満</MenuItem>
                    <MenuItem value="80">80㎡未満</MenuItem>
                    <MenuItem value="90">90㎡未満</MenuItem>
                    <MenuItem value="100">100㎡未満</MenuItem>
                    <MenuItem value="110">110㎡未満</MenuItem>
                    <MenuItem value="120">120㎡未満</MenuItem>
                    <MenuItem value="130">130㎡未満</MenuItem>
                    <MenuItem value="140">140㎡未満</MenuItem>
                    <MenuItem value="150">150㎡未満</MenuItem>
                    <MenuItem value="160">160㎡未満</MenuItem>
                    <MenuItem value="170">170㎡未満</MenuItem>
                    <MenuItem value="180">180㎡未満</MenuItem>
                    <MenuItem value="190">190㎡未満</MenuItem>
                    <MenuItem value="200">200㎡未満</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                建物面積
              </Typography>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={
                      minBuildingArea !== undefined
                        ? String(minBuildingArea)
                        : '下限なし'
                    }
                    onChange={(event: SelectChangeEvent) =>
                      setBuildingAreaMin(
                        event.target.value === '下限なし'
                          ? undefined
                          : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="下限なし">下限なし</MenuItem>
                    <MenuItem value="40">40㎡以上</MenuItem>
                    <MenuItem value="50">50㎡以上</MenuItem>
                    <MenuItem value="60">60㎡以上</MenuItem>
                    <MenuItem value="70">70㎡以上</MenuItem>
                    <MenuItem value="80">80㎡以上</MenuItem>
                    <MenuItem value="90">90㎡以上</MenuItem>
                    <MenuItem value="100">100㎡以上</MenuItem>
                    <MenuItem value="110">110㎡以上</MenuItem>
                    <MenuItem value="120">120㎡以上</MenuItem>
                    <MenuItem value="130">130㎡以上</MenuItem>
                    <MenuItem value="140">140㎡以上</MenuItem>
                    <MenuItem value="150">150㎡以上</MenuItem>
                    <MenuItem value="160">160㎡以上</MenuItem>
                    <MenuItem value="170">170㎡以上</MenuItem>
                    <MenuItem value="180">180㎡以上</MenuItem>
                    <MenuItem value="190">190㎡以上</MenuItem>
                    <MenuItem value="200">200㎡以上</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ mx: 1 }}>~</Typography>
                <FormControl fullWidth>
                  <Select
                    value={
                      maxBuildingArea !== undefined
                        ? String(maxBuildingArea)
                        : '上限なし'
                    }
                    onChange={(event: SelectChangeEvent) =>
                      setBuildingAreaMax(
                        event.target.value === '上限なし'
                          ? undefined
                          : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="上限なし">上限なし</MenuItem>
                    <MenuItem value="40">40㎡未満</MenuItem>
                    <MenuItem value="50">50㎡未満</MenuItem>
                    <MenuItem value="60">60㎡未満</MenuItem>
                    <MenuItem value="70">70㎡未満</MenuItem>
                    <MenuItem value="80">80㎡未満</MenuItem>
                    <MenuItem value="90">90㎡未満</MenuItem>
                    <MenuItem value="100">100㎡未満</MenuItem>
                    <MenuItem value="110">110㎡未満</MenuItem>
                    <MenuItem value="120">120㎡未満</MenuItem>
                    <MenuItem value="130">130㎡未満</MenuItem>
                    <MenuItem value="140">140㎡未満</MenuItem>
                    <MenuItem value="150">150㎡未満</MenuItem>
                    <MenuItem value="160">160㎡未満</MenuItem>
                    <MenuItem value="170">170㎡未満</MenuItem>
                    <MenuItem value="180">180㎡未満</MenuItem>
                    <MenuItem value="190">190㎡未満</MenuItem>
                    <MenuItem value="200">200㎡未満</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                間取り
              </Typography>
              <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
                {ROOM_TYPE_OPTIONS.map((type) => (
                  <FormControlLabel
                    key={type.label}
                    control={
                      <Checkbox
                        checked={floorPlanRooms.includes(type.value)}
                        onChange={() => handleRoomTypeChange(type.value)}
                      />
                    }
                    label={type.label}
                    sx={{ margin: 0, width: '50%' }}
                  />
                ))}
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                駅徒歩分
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={access !== undefined ? String(access) : '指定なし'}
                  onChange={(event: SelectChangeEvent) =>
                    setAccess(
                      event.target.value === '指定なし'
                        ? undefined
                        : Number(event.target.value)
                    )
                  }
                >
                  <MenuItem value="指定なし">指定なし</MenuItem>
                  {ACCESS_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={String(option.value)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                企業を選ぶ
              </Typography>
              <Stack sx={{ flexDirection: 'column', ml: 3 }}>
                {organizations.slice(0, 10).map((organization) => (
                  <FormControlLabel
                    key={organization.id}
                    control={
                      <Checkbox
                        checked={selectedCompanies.includes(organization.id)}
                        onChange={() => handleCompanyChange(organization.id)}
                      />
                    }
                    label={organization.name}
                  />
                ))}
              </Stack>
            </>
          )}
        </Box>
      </CardContent>
      <CardActions
        sx={{ backgroundColor: '#e3f2fd', justifyContent: 'center', py: 3 }}
      >
        <Box textAlign="center" width="100%">
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              onSearch({
                ...condition,
                access: access === undefined ? undefined : access,
                floorPlanRooms:
                  floorPlanRooms.length > 0 ? floorPlanRooms : undefined,
                locationIds: selectedLocations,
                maxBuildingArea,
                maxLandArea,
                maxPrice,
                minBuildingArea,
                minLandArea,
                minPrice,
                organizationId: [...selectedCompanies, ...childrenIds],
              })
            }
          >
            検索結果を見る
          </Button>
          <Button
            size="small"
            color="black"
            sx={{ fontWeight: 'normal', mt: 1, textDecoration: 'underline' }}
            onClick={clearAllConditions}
          >
            すべての条件をクリア
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
