import { Wrapper } from '@googlemaps/react-wrapper';
import { ReactElement, useEffect, useState } from 'react';

import { BaseMap } from './BaseMap';
import { Marker } from './Marker';

import { useSetSnackbar } from '@app/hooks/useSetSnackbar';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${API_KEY}&latlng=`;

export interface MapProps {
  coordinates?: number[];
}

export function Map({ coordinates }: MapProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>();
  const zoom = 14;

  useEffect(() => {
    if (coordinates) {
      const fetchCoordinates = async () => {
        try {
          const coordinatesString = coordinates.join(',');
          const response = await fetch(
            `${GEOCODING_API_URL}${coordinatesString}`
          );
          const data = await response.json();

          if (data.status === 'OK') {
            const location = data.results[0].geometry.location;
            setCenter({ lat: location.lat, lng: location.lng });
          } else {
            throw new Error('Geocoding failed:', data.status);
          }
        } catch (error) {
          setSnackbar(true, '住所情報の取得に失敗しました', 'error');
          console.error('Error fetching geocoding data:', error);
        }
      };

      void fetchCoordinates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, setSnackbar]);

  if (!API_KEY) {
    return <></>;
  }
  return (
    <Wrapper apiKey={API_KEY}>
      <BaseMap
        center={center}
        zoom={zoom}
        style={{ height: '320px', width: '100%' }}
      >
        <Marker position={center} />
      </BaseMap>
    </Wrapper>
  );
}
