import { Box, Grid, Typography, Divider } from '@mui/material';
import React from 'react';

import { TextPretty } from '@app/components/Shared/TextPretty';
import { theme } from '@app/theme';
import { OrganizationStrength } from '@app/types/organization';

export function OrganizationStrengths({
  data,
}: {
  data: OrganizationStrength[];
}) {
  return (
    <Box sx={{ mt: 3, px: { md: 8, xs: 2 } }}>
      <Typography variant="h3" fontWeight="bold">
        この会社を選ぶ理由
      </Typography>
      {data.map((item, index) => (
        <Grid container spacing={2} key={index} sx={{ my: 5 }}>
          <Grid item xs={12} md={4}>
            <Box
              component="div"
              sx={{
                backgroundImage: `url(${item.image?.url})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '8px',
                height: '288px',
                width: '100%',
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box ml={1}>
              <Box
                sx={{
                  background:
                    'linear-gradient(to right, #e0f7fa, #e0f2f1, #e0f7fa)',
                  borderRadius: '16px',
                  display: 'inline-block',
                  mb: 1,
                  px: 2,
                  py: 0.5,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: 'bold',
                  }}
                >
                  Point.{index + 1}
                </Typography>
              </Box>
              <TextPretty text={item.title} color="#0A2252" formType="boldSm" />
              <TextPretty
                text={item.description}
                color="#0A2252"
                formType="lightBase"
              />
            </Box>
          </Grid>
        </Grid>
      ))}
      <Divider sx={{ my: 5 }} />
    </Box>
  );
}
