import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, IconButton, Stack } from '@mui/material';
import { useState } from 'react';

export function AccordionSection() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        mt: 4,
        width: { md: '700px' },
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          textAlign: 'left',
        }}
        onClick={toggleAccordion}
      >
        <Typography
          variant="h4"
          color="primary"
          sx={{ textDecoration: 'underline' }}
        >
          個人情報の取り扱い及び電話SMS電子メールについて
        </Typography>
        <IconButton
          sx={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Stack>

      {isOpen && (
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            ・株式会社プロクラは、資料及び展示場予約の請求等（以下「資料請求等」といいます。）をしていただいた皆様からお預かりした個人情報を、皆様が本サービス上で資料請求等の対象として選択した企業等（当社の顧客企業、当社が本サービスを提供する上で必要な提携・契約関係を有する企業等をいいます。）に提供いたします。必須項目に不備があった場合、当該企業等からの各サービスを受けられないことがございますので、あらかじめご了承ください。
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            ・資料請求等をしていただいた皆様からお預かりした個人情報は、企業等において、資料送付、展示場の予約等及びこれに付随する連絡・皆様と企業等との契約の締結・企業等によるサービス提供・皆様からのお問い合わせへの回答・各種イベントのご案内・関連物件または実例のお知らせ等の目的で利用・管理・保管されます。（詳細はプライバシーポリシーをご確認ください。）
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            ・株式会社プロクラは本サービスを円滑に遂行するため、お預かりした個人情報を一定期間保管し、株式会社プロクラからサービス提供についてお電話もしくはSMSや電子メールにて確認のご連絡をさせていただくことがございます。
          </Typography>
          <Typography variant="body2">
            ・株式会社プロクラにおける個人情報の取扱い、個人情報の開示・訂正・利用停止及びお問い合わせ窓口等についてはプライバシーポリシーをご覧ください。
          </Typography>
        </Box>
      )}
    </Box>
  );
}
