import {
  differenceInCalendarDays,
  format,
  isToday,
  isYesterday,
  isThisYear,
  parseISO,
  parse,
  isValid,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

export const weeks = [
  { label: '月曜', value: 1 },
  { label: '火曜', value: 2 },
  { label: '水曜', value: 3 },
  { label: '木曜', value: 4 },
  { label: '金曜', value: 5 },
  { label: '土曜', value: 6 },
  { label: '日曜', value: 0 },
] as const;

export function getCloneDate(date: Date): Date {
  return new Date(date.getTime());
}

/**
 * 日付を跨ぐ時間を"翌"などの文字列を付与して返す
 * @param time string
 * @returns string
 */
export function getConvertTimeToJp(time: string): string {
  const timeArr = time.split(':').map((t) => Number(t));
  if (timeArr.length < 2) return '';

  const date = new Date();
  date.setHours(timeArr[0], timeArr[1], 0);
  const formatTime = format(date, 'HH:mm');
  const diff = differenceInCalendarDays(date, new Date());

  if (diff === 0) return formatTime;
  else if (diff === 1) return `翌${formatTime}`;
  else if (diff === 2) return `翌々${formatTime}`;
  return `${diff}日後${formatTime}`;
}

/**
 * 指定の日付を日本語フォーマットに変換して返す
 * @param date Date|string
 * @returns string
 */
export function formatRelativeDate(date: Date | string) {
  const dateObj = convertToDate(date);
  if (isToday(dateObj)) {
    return '今日';
  } else if (isYesterday(dateObj)) {
    return '昨日';
  } else if (isThisYear(dateObj)) {
    return format(dateObj, 'M月d日(E)', { locale: ja });
  }
  return format(dateObj, 'yyyy年M月d日(E)', { locale: ja });
}

/**
 * 指定の日付と現在の差分を日本語に変換して返す
 * @param date Date|string
 * @returns string
 */
export function getDateToDiffJp(date: Date | string) {
  const dateObj = convertToDate(date);
  const differenceInMilliseconds = new Date().getTime() - dateObj.getTime();
  const differenceInHours = differenceInMilliseconds / (60 * 60 * 1000);
  const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);
  const differenceInWeeks =
    differenceInMilliseconds / (7 * 24 * 60 * 60 * 1000);

  if (differenceInHours < 24) {
    return format(dateObj, 'HH:mm');
  } else if (differenceInDays < 8) {
    return `${Math.floor(differenceInDays)}日前`;
  } else if (differenceInDays < 30) {
    return `${Math.floor(differenceInWeeks)}週間前`;
  }
  const differenceInMonths = differenceInDays / 30;
  return `${Math.floor(differenceInMonths)}ヶ月前`;
}

function convertToDate(date: Date | string) {
  return typeof date === 'string' ? parseISO(date) : date;
}

/**
 * 日付文字列をパースしてDateオブジェクトに変換する（yyyy/MM/ddおよびyyyy/MMに対応）
 * @param dateString - 日付文字列
 * @returns - パースした日付
 */
export function parseDateStringToDateObj(dateString: string): Date {
  // NOTE: データ定義に基づき、日付文字列はyyyy/MM/dd形式またはyyyy/MM形式とする。
  // yyyy/MM/dd形式でパース
  const parsedDateFull = parse(dateString, 'yyyy/MM/dd', new Date());
  if (isValid(parsedDateFull)) {
    return parsedDateFull;
  }
  // yyyy/MM形式でパース
  const parsedDate = parse(dateString, 'yyyy/MM', new Date());
  return parsedDate;
}
