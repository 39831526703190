import { Box } from '@mui/material';
import { ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from '@app/components/Shared/Loading';
import { TopNav } from '@app/components/TopNav';

const TOTAL_NAV_FOOTER_HEIGHT = '150px';

export function DefaultLayout({ showAccount = true }): ReactElement {
  return (
    <>
      <TopNav showAccount={showAccount} />
      <Suspense fallback={<Loading />}>
        <Box
          component="main"
          minHeight={`calc(100vh - ${TOTAL_NAV_FOOTER_HEIGHT})`}
        >
          <Outlet />
        </Box>
      </Suspense>
    </>
  );
}
