import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Grid, Typography, Box, Button } from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

interface StrengthsSectionProps {
  features: string[];
  mainCatch: string;
  showReadMore?: boolean;
  subCatch: string;
}

interface ReadMoreProps {
  maxLines?: number;
  text: string;
}

function ReadMore({ text, maxLines = 2 }: ReadMoreProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * maxLines;
      if (textRef.current.scrollHeight > maxHeight) {
        setIsTruncated(true);
      }
    }
  }, [maxLines, text]);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Box
        ref={textRef}
        sx={{
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isExpanded ? 'none' : maxLines,
          display: '-webkit-box',
          overflow: 'hidden',
        }}
      >
        <Typography variant="body1" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </Box>
      {isTruncated && (
        <Button onClick={handleToggle} sx={{ fontWeight: 300 }}>
          {isExpanded ? '表示を折りたたむ' : '続きを読む'}
        </Button>
      )}
    </div>
  );
}

export function FeaturesSection({
  features,
  mainCatch,
  subCatch,
  showReadMore = true,
}: StrengthsSectionProps): ReactElement {
  return (
    <Box sx={{ width: 1 }}>
      <Typography variant="h3" fontWeight="bold" mb={3}>
        会社の特徴
      </Typography>
      <Grid container>
        {features.map((strength, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={index}
            sx={{ alignItems: 'center', display: 'flex', my: 0.5 }}
          >
            <HomeOutlinedIcon style={{ marginRight: '8px' }} />
            <Typography variant="body1">{strength}</Typography>
          </Grid>
        ))}
      </Grid>
      <Typography variant="body1" fontWeight={600} sx={{ mt: 2 }}>
        {mainCatch}
      </Typography>
      {showReadMore ? (
        <ReadMore text={subCatch} />
      ) : (
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{subCatch}</Typography>
      )}
    </Box>
  );
}
