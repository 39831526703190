import {
  Container,
  Link as MUILink,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Copyright } from '@app/components/Shared/Copyright';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Logo } from '@app/components/Shared/Logo';
import { imageAssets } from '@app/theme';

export function Register(): ReactElement {
  const handleButtonClick = () => {
    const baseUrl = process.env.REACT_APP_PRO_CLO_URL;
    const signUpUrl = `${baseUrl}/sign-up/owner?inflow=PORTAL`;
    window.open(signUpUrl, '_blank');
  };

  return (
    <>
      <HeadBlock />
      <Container maxWidth={false} disableGutters>
        <Box display="flex" height="100vh" width="100%">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            sx={{ p: 8 }}
          >
            <Stack justifyContent="center" sx={{ height: '100%', px: 10 }}>
              <Logo />
              <Stack spacing={3} sx={{ mt: 6, py: 3 }}>
                <Typography variant="h3" fontWeight={600} sx={{ py: 3 }}>
                  アカウント作成
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleButtonClick}
                  sx={{ minWidth: 150, mx: 2 }}
                >
                  アカウントを作成する
                </Button>
              </Stack>
              <Box sx={{ my: 5 }}>
                <Typography component="div" align="center" variant="body2">
                  既にアカウントをお持ちですか？
                  <MUILink component={Link} to="/login" underline="none">
                    ログイン
                  </MUILink>
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ py: 2, textAlign: 'center' }}>
              <Copyright />
            </Box>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundImage: `url(${imageAssets.registerMainImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '100%',
            }}
          ></Box>
        </Box>
      </Container>
    </>
  );
}
