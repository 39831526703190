interface MarkerProps {
  position?: google.maps.LatLngLiteral;
}

export const Marker: React.FC<MarkerProps> = (options) => {
  const marker = new google.maps.Marker();

  marker.setOptions({
    ...options,
    draggable: false,
  });

  return null;
};
