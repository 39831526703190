/** blocks-1d31 */
export interface Pagination<T> {
  '@nextLink': string;
  '@previousLink'?: string;
  count: number;
  total: number;
  value: T;
}

/** blocks-1d31 */
export enum LoadableState {
  HAS_ERROR = 'hasError',
  HAS_VALUE = 'hasValue',
  LOADING = 'loading',
  NOT_FOUND = 'notFound',
}

export const Common = {
  EMPTY: '',
} as const;
export type EmptyKey = typeof Common.EMPTY;

export interface GetListDataRequest {
  expand?: string;
  nextLink?: string;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  previousLink?: string;
}
